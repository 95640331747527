import React, { useEffect, useState } from 'react';
import { notify } from 'app/helper';
import BerryProducts from 'app/BerryProducts';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from 'features/cartSlice';
import { removeFromWishlist } from 'features/wishlistSlice';

function WishlistModal() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const products = useSelector(state => state.products.products);
  const wishlist = useSelector(state => state.wishlist.wishlist);
  const [parsedWishlist, setParsedWishlist] = useState([]);

  useEffect(() => {
    if (wishlist.length > 0 && products.length > 0) {
      const parsed = BerryProducts.getProductsBySKUs(wishlist, products);
      setParsedWishlist(parsed);
    } else {
      setParsedWishlist([]);
    }
  }, [wishlist, products]);

  const handleAddToCart = (sku, price) => {
    dispatch(addToCart({ sku, qty: 1, price }));
    notify("Product successfully added to cart!");
  };

  const handleRemove = (sku) => {
    const email = user.email;
    dispatch(removeFromWishlist({ email, sku }));
  };

  return (
    <div className="wishlist-popup">
      <div className="wishlist-popup-inner">
        <div className="wishlist-popup-content">
          <div className="wishlist-popup-content-top">
            <span className="wishlist-name">Wishlist </span>
            <span className="wishlist-count-wrapper">
              <span className="wishlist-count">{parsedWishlist.length}</span>
            </span>
            <span className="wishlist-popup-close"></span>
          </div>
          <div className="wishlist-popup-content-mid">
            <table className="wishlist-items">
              <tbody>
                {parsedWishlist.length === 0 ? (
                  <tr>
                    <td colSpan="4">Your wishlist is empty. <Link to="/shop">Go to Shop.</Link></td>
                  </tr>
                ) : (
                  parsedWishlist.map((item, index) => (
                    <tr className="wishlist-item" key={index}>
                      <td className="wishlist-item-remove">
                        <span onClick={() => handleRemove(item.sku)}></span>
                      </td>
                      <td className="wishlist-item-image">
                        <Link to={`/product/${item.slug}`}>
                          <img width="600" height="600" src={`/${item.images[0]}`} alt={item.name} />
                        </Link>
                      </td>
                      <td className="wishlist-item-info">
                        <div className="wishlist-item-name">
                          <Link to={`/product/${item.slug}`}>{item.name}</Link>
                        </div>
                        <div className="wishlist-item-price">
                          {item.price_discounted ? (
                            <>
                              <del aria-hidden="true"><span>${item.price.toFixed(2)}</span></del>
                              <ins><span> ${item.price_discounted.toFixed(2)}</span></ins>
                            </>
                          ) : (
                            <span>${item.price.toFixed(2)}</span>
                          )}
                        </div>
                      </td>
                      <td className="wishlist-item-actions">
                        <div className="wishlist-item-stock">In stock</div>
                        <div className="wishlist-item-add">
                          <div className="btn-add-to-cart" data-title="Add to cart">
                            {item.price_discounted ? (
                              <a href="#" rel="nofollow" className="product-btn button" onClick={(e) => { e.preventDefault(); handleAddToCart(item.sku, item.price_discounted) }}>Add to cart</a>
                            ) : (
                              <a href="#" rel="nofollow" className="product-btn button" onClick={(e) => { e.preventDefault(); handleAddToCart(item.sku, item.price) }}>Add to cart</a>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="wishlist-popup-content-bot">
            <div className="wishlist-popup-content-bot-inner">
              <Link to="/wishlist" className="wishlist-page">
                Open wishlist page
              </Link>
              <span className="wishlist-continue" data-url="">
                Continue shopping
              </span>
            </div>
            <div className="wishlist-notice wishlist-notice-show">Added to the wishlist!</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WishlistModal;
