import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from 'features/cartSlice';
import { notify } from 'app/helper';
import { closeQuickview } from 'features/quickviewSlice';

function QuickViewModal() {
  const dispatch = useDispatch();
  const shouldShow = useSelector(state => state.quickview.shouldShow);
  const product = useSelector(state => state.quickview.product);
  const [quantity, setQuantity] = useState(1);

  const handleAddToCart = (e) => {
    e.preventDefault();
    if (product) {
      dispatch(addToCart({ sku: product.sku, qty: quantity, price: product.price }));
      notify("Product added to cart!");
    }
  };

  const handleQuantityChange = (e) => {
    const value = Math.max(1, parseInt(e.target.value, 10) || 1);
    setQuantity(value);
  };

  const handleDismiss = (e) => {
    e.preventDefault();
    dispatch(closeQuickview());
  }

  const incrementQuantity = () => setQuantity(prev => prev + 1);
  const decrementQuantity = () => setQuantity(prev => Math.max(1, prev - 1));

  useEffect(() => {
    window.initializeSlickSliders();
  }, [product]);

  if (!product) {
    return null;
  }

  return (
    <div className={`quickview-popup ${shouldShow ? 'active' : ''}`}>
      <div id="quickview-container">
        <div className="quickview-container">
          <a href="#" onClick={handleDismiss} className="quickview-close"></a>
          <div className="quickview-notices-wrapper"></div>
          <div className="product single-product product-type-simple">
            <div className="product-detail">
              <div className="row">
                <div className="img-quickview">
                  <div className="product-images-slider">
                    <div id="quickview-slick-carousel">
                      <div className="images">
                        <div className="scroll-image">
                          <div className="slick-wrap">
                            <div className="slick-sliders image-additional" data-dots="true" data-columns4="1" data-columns3="1" data-columns2="1" data-columns1="1" data-columns="1" data-nav="true">
                              {product.images && product.images.map((image, index) => (
                                <div key={index} className="img-thumbnail slick-slide">
                                  <a href={image} className="image-scroll" title="">
                                    <img width="900" height="900" src={image} alt="" />
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="quickview-single-info">
                  <div className="product-content-detail entry-summary">
                    <h1 className="product-title entry-title">{product.name}</h1>
                    <div className="price-single">
                      <div className="price">
                        {product.price_discounted && <del><span>${product.price}</span></del>}
                        <span>${product.price_discounted || product.price}</span>
                      </div>
                    </div>
                    <div className="product-rating">
                      <div className="star-rating" role="img" aria-label={`Rated ${product.rating} out of 5`}>
                        <span style={{ width: `${product.rating * 20}%` }}>
                          Rated <strong className="rating">{product.rating}</strong> out of 5 based on <span className="rating">{product.reviews.length}</span> customer reviews
                        </span>
                      </div>
                      <a href="#" className="review-link">(<span className="count">{product.reviews.length}</span> customer reviews)</a>
                    </div>
                    <div className="description">
                      <p>{product.description}</p>
                    </div>
                    <form className="cart" onSubmit={handleAddToCart}>
                      <div className="quantity-button">
                        <div className="quantity">
                          <button type="button" className="plus" onClick={incrementQuantity}>+</button>
                          <input type="number" className="input-text qty text" step="1" min="1" name="quantity" value={quantity} title="Qty" size="4" onChange={handleQuantityChange} inputMode="numeric" autoComplete="off" />
                          <button type="button" className="minus" onClick={decrementQuantity}>-</button>
                        </div>
                        <button type="submit" className="single-add-to-cart-button button alt">Add to cart</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
}

export default QuickViewModal;
