import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('orders', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('orders');
  return data ? JSON.parse(data) : defaultValue;
};

const initialOrders = {
  orders: [],
  allOrders: [
    {
      id: 1,
      user: "johndoe@example.com",
      date: "March 25, 2020",
      status: "Completed",
      items: [
        {
          sku: "D2300-3-2-2",
          price: 90,
          variant: "Size:S;Color:Black",
          qty: 1
        }
      ],
      deliver_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      billed_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      shipping: 20,
      total: 110,
    },
    {
      id: 2,
      user: "janedoe@example.com",
      date: "October 31, 2023",
      status: "Refunded",
      items: [
        {
          sku: "IM402-2-3-8",
          price: 110,
          variant: "Size:L;Color:Black",
          qty: 2
        }
      ],
      deliver_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      billed_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      shipping: 30,
      total: 250,
    },
    {
      id: 3,
      user: "bobsmith@example.com",
      date: "June 16, 2024",
      status: "Processing",
      items: [
        {
          sku: "BM542-1-7-8",
          price: 180,
          variant: "Size:L;Color:Beige",
          qty: 1
        }
      ],
      deliver_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      billed_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      shipping: 60,
      total: 240,
    },
    {
      id: 4,
      user: "alicejones@example.com",
      date: "30 April, 2024",
      status: "Pending",
      items: [
        {
          sku: "HRC09-8-7-5",
          price: 120,
          variant: "Size:M;Color:White",
          qty: 5
        }
      ],
      deliver_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      billed_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      shipping: 100,
      total: 700,
    },
    {
      id: 5,
      user: "charliebrown@example.com",
      date: "February 07, 2024",
      status: "Cancelled",
      items: [
        {
          sku: "GV312-8-3-1",
          price: 130,
          variant: "Size:M;Color:RedBrick",
          qty: 2
        }
      ],
      deliver_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      billed_to: { line1: "Jl Sekarat John", line2: "Komplek Billing No 1", line3: "Medan, 12345", line4: "+62 234 5678 9012" },
      shipping: 45,
      total: 305,
    },
  ],
};

const initialState = loadFromLocalStorage(initialOrders);

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
      updateLocalStorage(state);
    },
    setOrdersByEmail: (state, action) => {
      const email = action.payload;
      if (email) {
        state.orders = state.allOrders.filter(order => order.user === email);
      }
      updateLocalStorage(state);
    },
    addOrder: (state, action) => {
      const newOrder = action.payload;
      state.allOrders.push(newOrder);
      state.orders = state.allOrders.filter(order => order.user === newOrder.user);
      updateLocalStorage(state);
    },
    removeOrder: (state, action) => {
      const orderId = action.payload;
      state.allOrders = state.allOrders.filter(order => order.id !== orderId);
      state.orders = state.orders.filter(order => order.id !== orderId);
      updateLocalStorage(state);
    },
    clearOrders: (state) => {
      state.orders = [];
      updateLocalStorage(state);
    }
  }
});

export const { setOrders, setOrdersByEmail, addOrder, removeOrder, clearOrders } = ordersSlice.actions;
export default ordersSlice.reducer;
