import { createSlice } from '@reduxjs/toolkit';
import { omitKeys } from 'app/helper';
import { clearAddress, setAddressByEmail } from 'features/adressSlice';
import { setOrdersByEmail, clearOrders } from 'features/ordersSlice';
import { clearWishlist, setWishlistByEmail } from 'features/wishlistSlice';

const updateLocalStorage = (data) => {
  localStorage.setItem('users', JSON.stringify(omitKeys(data, ['error', 'loginErrorMessage', 'registerErrorMessage', 'updateErrorMessage', 'resetPasswordToken'])));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('users');
  return data ? JSON.parse(data) : defaultValue;
};

const generateToken = () => Math.random().toString(36).substr(2, 8);

const initialUsers = {
  authenticated: false,
  user: null,
  allUsers: {
    'admin@example.com': { email: 'admin@example.com', password: 'secure_admin', firstName: 'Site', lastName: 'Admin', displayName: 'Site Admin', isAdmin: true },
    'johndoe@example.com': { email: 'johndoe@example.com', password: 'secure1', firstName: 'John', lastName: 'Doe', displayName: 'John Doe' },
    'janedoe@example.com': { email: 'janedoe@example.com', password: 'secure2', firstName: 'Jane', lastName: 'Doe', displayName: 'Jane Doe' },
    'bobsmith@example.com': { email: 'bobsmith@example.com', password: 'secure3', firstName: 'Bob', lastName: 'Smith', displayName: 'Bob Smith' },
    'alicejones@example.com': { email: 'alicejones@example.com', password: 'secure4', firstName: 'Alice', lastName: 'Jones', displayName: 'Alice Jones' },
    'charliebrown@example.com': { email: 'charliebrown@example.com', password: 'secure5', firstName: 'Charlie', lastName: 'Brown', displayName: 'Charlie Brown' }
  },
  resetTokens: {},
};

const initialState = loadFromLocalStorage(initialUsers);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.authenticated = true;
      state.user = action.payload;
      // If only we can dispatch another reducer from here, this is annoyingly repetitive
      state.loginErrorMessage = null;
      state.registerErrorMessage = null;
      state.updateErrorMessage = null;
      localStorage.setItem('user', JSON.stringify(action.payload));
      updateLocalStorage(state);
    },
    loginFailed: (state, action) => {
      state.loginErrorMessage = action.payload;
    },
    endSession: (state) => {
      state.authenticated = false;
      state.user = null;
      state.loginErrorMessage = null;
      state.registerErrorMessage = null;
      state.updateErrorMessage = null;
      updateLocalStorage(state);
    },
    clearError: (state) => {
      state.loginErrorMessage = null;
      state.registerErrorMessage = null;
      state.updateErrorMessage = null;
    },
    register: (state, action) => {
      const { email, password, firstName, lastName } = action.payload;
      if (!email || !password || !firstName || !lastName) {
        state.registerErrorMessage = 'All fields are required';
        return;
      }
      if (password.length < 6) {
        state.registerErrorMessage = 'Password needs to be at least 6 characters long';
        return;
      }
      if (!!state.allUsers[email]) {
        state.registerErrorMessage = 'User already exists';
        return;
      }

      const newUser = { email, password, firstName, lastName, displayName: `${firstName} ${lastName}` };
      state.allUsers[email] = newUser;
      state.authenticated = true;
      state.user = newUser;
      state.loginErrorMessage = null;
      state.registerErrorMessage = null;
      state.updateErrorMessage = null;
      updateLocalStorage(state);
    },
    updateUserDetails: (state, action) => {
      const { email, firstName, lastName, displayName } = action.payload;
      const user = state.allUsers[email];
      if (!user) {
        state.updateErrorMessage = 'User not found';
        return;
      }
      if (!(state.user && state.user.email === email)){
        state.updateErrorMessage = 'Failed to update user details';
        return;
      }

      state.user = { ...state.user, firstName, lastName, displayName };
      state.allUsers[email] = state.user;
      updateLocalStorage(state);
    },
    updateUserPassword: (state, action) => {
      const { email, currentPassword, newPassword } = action.payload;
      const user = state.allUsers[email];
      if (!user) {
        state.updateErrorMessage = 'User not found';
        return;
      }
      if (!(state.user && state.user.email === email)){
        state.updateErrorMessage = 'Failed to update user details';
        return;
      }
      if (state.user.password !== currentPassword){
        state.updateErrorMessage = 'Incorrect password given';
        return;
      }
      if (newPassword.length < 6) {
        state.updateErrorMessage = 'New Password needs to be at least 6 characters long';
        return;
      }

      state.user = { ...state.user, password: newPassword };
      state.allUsers[email] = state.user;
      updateLocalStorage(state);
    },
    generateResetToken: (state, action) => {
      const { email } = action.payload;
      if (!state.allUsers[email]) {
        // Silently does nothing
        return;
      }
      const token = generateToken();
      state.resetTokens[token] = email;
      state.resetPasswordToken = token;
      updateLocalStorage(state);
    },
    resetPassword: (state, action) => {
      const { token, newPassword } = action.payload;
      if (!state.resetTokens[token]) {
        state.updateErrorMessage = 'Unable to perform password reset';
        return;
      }
      if (newPassword.length < 6) {
        state.updateErrorMessage = 'New Password needs to be at least 6 characters long';
        return;
      }

      state.allUsers[state.resetTokens[token]].password = newPassword;
      delete state.resetTokens[token];
      state.updateErrorMessage = null;
      updateLocalStorage(state);
    }
  }
});

export const { setUser, loginFailed, endSession, clearError, register, updateUserDetails, updateUserPassword, generateResetToken, resetPassword } = authSlice.actions;

export const login = ({ email, password }) => (dispatch, getState) => {
  const { allUsers } = getState().auth;
  const user = allUsers[email];
  if (!user) {
    dispatch(loginFailed('Invalid email or password'));
    return;
  }
  if (user.password !== password) {
    dispatch(loginFailed('Invalid email or password'));
    return;
  }
  dispatch(setUser(user));
  dispatch(setOrdersByEmail(user.email));
  dispatch(setWishlistByEmail(user.email));
  dispatch(setAddressByEmail(user.email));
};

export const logout = () => (dispatch) => {
  dispatch(endSession());
  dispatch(clearOrders());
  dispatch(clearWishlist());
  dispatch(clearAddress());
};

export const requestResetToken = (email) => (dispatch) => {
  // Mock email sending here
  dispatch(generateResetToken({ email }));
};

export const performPasswordReset = ({ token, newPassword }) => (dispatch) => {
  dispatch(resetPassword({ token, newPassword }));
};

export default authSlice.reducer;
