import BerryProducts from 'app/BerryProducts';
import { notify } from 'app/helper';
import { addToCart } from 'features/cartSlice';
import { addToCompare } from 'features/compareSlice';
import { addReview } from 'features/productsSlice';
import { addToWishlist } from 'features/wishlistSlice';
import PageLayout from 'layouts/PageLayout';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

function ProductDetails() {
  const dispatch = useDispatch();
  const { productSlug } = useParams();
  const auth = useSelector(state => state.auth);
  const wishlist = useSelector(state => state.wishlist.wishlist);
  const products = useSelector(state => state.products.products);
  const productDetails = BerryProducts.getProductBySlug(productSlug, products);
  const isProductInWishlist = wishlist.includes(productDetails.sku);

  const [quantity, setQuantity] = useState(1);

  // For leaving review
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [reviewAuthor, setReviewAuthor] = useState('');

  const handleAddToCart = (sku, price) => {
    dispatch(addToCart({ sku, qty: quantity, price }));
  };

  const handleAddToWishlist = () => {
    if (!auth.authenticated) {
      notify("You need to be signed in to do that!");
      return;
    }
    if (isProductInWishlist) {
      openWishlistModal();
    } else {
      dispatch(addToWishlist({ email: auth.user.email, sku: productDetails.sku }));
      notify("Product added to wishlist!");
    }
  };

  const handleQuantityChange = (value) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + value));
  };

  const handleReviewSubmit = (e) => {
    e.preventDefault();
    const review = {
      by: reviewAuthor,
      on: new Date().toLocaleDateString(),
      rating,
      text: reviewText,
    };
    dispatch(addReview({ sku: productDetails.sku, review }));
    setRating(0);
    setReviewText('');
    setReviewAuthor('');
  };

  const openWishlistModal = () => {
    window.jQuery(".wishlist-notice").addClass("wishlist-notice-show");
    window.jQuery(".wishlist-popup").addClass("show");
    setTimeout(() => {
      window.jQuery(".wishlist-notice").removeClass("wishlist-notice-show");
    }, 1000);
  };

  const openCompareModal = () => {
    window.jQuery(".compare-popup").addClass("active");
  };

  const handleAddToCompare = (e) => {
    e.preventDefault();
    dispatch(addToCompare(productDetails));
    openCompareModal();
  };

  return (
    <PageLayout headerTitle={"Product Details"}>
      <div className="shop-details zoom" data-product_layout_thumb="scroll" data-zoom_scroll="true" data-zoom_contain_lens="true" data-zoomtype="inner" data-lenssize="200" data-lensshape="square" data-lensborder="" data-bordersize="2" data-bordercolour="#f9b61e" data-popup="false">
        <div className="product-top-info">
          <div className="section-padding">
            <div className="section-container p-l-r">
              <div className="row">
                <div className="product-images col-lg-7 col-md-12 col-12">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="content-thumbnail-scroll">
                        <div className="image-thumbnail slick-carousel slick-vertical" data-asnavfor=".image-additional" data-centermode="true" data-focusonselect="true" data-columns4="5" data-columns3="4" data-columns2="4" data-columns1="4" data-columns="4" data-nav="true" data-vertical="&quot;true&quot;" data-verticalswiping="&quot;true&quot;">
                          {productDetails.images.map((image) => {
                            return (
                              <div className="img-item slick-slide" key={image}>
                                <span className="img-thumbnail-scroll">
                                  <img width="600" height="600" src={`/${image}`} alt={productDetails.sku} />
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="scroll-image main-image">
                        <div className="image-additional slick-carousel" data-asnavfor=".image-thumbnail" data-fade="true" data-columns4="1" data-columns3="1" data-columns2="1" data-columns1="1" data-columns="1" data-nav="true">
                          {productDetails.images.map((image) => {
                            return (
                              <div className="img-item slick-slide" key={image}>
                                <img width="900" height="900" src={`/${image}`} alt={productDetails.sku} title="" />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-info col-lg-5 col-md-12 col-12 ">
                  <h1 className="title">{productDetails.name}</h1>
                  <span className="price">
                    {productDetails.price_discounted ? (
                      <>
                        <del aria-hidden="true"><span>${productDetails.price}</span></del>
                        <ins><span> ${productDetails.price_discounted}</span></ins>
                      </>
                    ) : (
                      <span>${productDetails.price}</span>
                    )}
                  </span>
                  <div className="rating">
                    <div className={`star star-${Math.round(productDetails.reviews.reduce((sum, review) => sum + review.rating, 0) / productDetails.reviews.length)}`}></div>
                    <div className="review-count">
                      ({productDetails.reviews.length} reviews)
                    </div>
                  </div>
                  <div className="description">
                    <p>{productDetails.description}</p>
                  </div>
                  <div className="buttons">
                    <div className="add-to-cart-wrap">
                      <div className="quantity">
                        <button type="button" className="plus" onClick={() => handleQuantityChange(1)}>+</button>
                        <input type="number" className="qty" step="1" min="0" max="999" name="quantity" value={quantity} title="Qty" size="4" placeholder="" inputMode="numeric" autoComplete="off" onChange={e => setQuantity(Number(e.target.value))} />
                        <button type="button" className="minus" onClick={() => handleQuantityChange(-1)}>-</button>
                      </div>
                      <div className="btn-add-to-cart">
                        <a href="#" className="button" tabIndex="0" onClick={(e) => { e.preventDefault(); handleAddToCart(productDetails.sku, productDetails.price_discounted) }}>Add to cart</a>
                      </div>
                    </div>
                    <div className="btn-quick-buy"></div>
                    <div className="btn-wishlist" data-title="Wishlist">
                      <button
                        onClick={handleAddToWishlist}
                        className={`product-btn ${isProductInWishlist ? 'added' : ''}`}>
                        {isProductInWishlist ? 'In Wishlist' : 'Add to wishlist'}
                      </button>
                    </div>
                    <div className="btn-compare" data-title="Compare">
                      <button className="product-btn" onClick={handleAddToCompare}>Compare</button>
                    </div>
                  </div>
                  <div className="product-meta">
                    <span className="sku-wrapper">SKU: <span className="sku">{productDetails.sku}</span></span>
                    <span className="posted-in">Category: <a href="shop-grid-left.html" rel="tag">{productDetails.category}</a></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-tabs">
          <div className="section-padding">
            <div className="section-container p-l-r">
              <div className="product-tabs-wrap">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#description" role="tab">Description</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#additional-information" role="tab">Additional information</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#reviews" role="tab">Reviews ({productDetails.reviews.length})</a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="description" role="tabpanel">
                    <p>{productDetails.description}</p>
                  </div>
                  <div className="tab-pane fade" id="additional-information" role="tabpanel">
                    <table className="product-attributes">
                      <tbody>
                        <tr className="attribute-item">
                          <th className="attribute-label">Color</th>
                          <td className="attribute-value">{productDetails.variations.color.join(', ')}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="tab-pane fade" id="reviews" role="tabpanel">
                    <div id="reviews" className="product-reviews">
                      <div id="comments">
                        <h2 className="reviews-title">{productDetails.reviews.length} review{productDetails.reviews.length !== 1 && 's'} for <span>{productDetails.name}</span></h2>
                        <ol className="comment-list">
                          {productDetails.reviews.map((review, index) => (
                            <li className="review" key={index}>
                              <div className="content-comment-container">
                                <div className="comment-container">
                                  <img src="/media/user.jpg" className="avatar" height="60" width="60" alt="" />
                                  <div className="comment-text">
                                    <div className="rating small">
                                      <div className={`star star-${review.rating}`}></div>
                                    </div>
                                    <div className="review-author">{review.by}</div>
                                    <div className="review-time">{review.on}</div>
                                  </div>
                                </div>
                                <div className="description">
                                  <p>{review.text}</p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ol>
                      </div>
                      <div id="review-form">
                        <div id="respond" className="comment-respond">
                          <span id="reply-title" className="comment-reply-title">Add a review</span>
                          <form action="#" method="post" id="comment-form" className="comment-form" onSubmit={handleReviewSubmit}>
                            <p className="comment-notes">
                              Required fields are marked <span className="required">*</span>
                            </p>
                            <div className="comment-form-rating">
                              <label htmlFor="rating">Your rating</label>
                              <p className="stars">
                                <span>
                                  {[1, 2, 3, 4, 5].map(star => (
                                    <a
                                      key={star}
                                      className={star <= rating ? `star-${star} selected` : `star-${star}`}
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setRating(star);
                                      }}
                                    >
                                      {star}
                                    </a>
                                  ))}
                                </span>
                              </p>
                              <div className="rating">
                                <div className={`star star-${rating}`}></div>
                              </div>
                            </div>
                            <p className="comment-form-comment">
                              <textarea id="comment" name="comment" cols="45" rows="4" value={reviewText} onChange={e => setReviewText(e.target.value)} placeholder='Your reviews*' required></textarea>
                            </p>
                            <div className="content-info-reviews">
                              <p className="comment-form-author">
                                <input id="author" name="author" type="text" placeholder='Name *' value={reviewAuthor} onChange={e => setReviewAuthor(e.target.value)} required />
                              </p>
                              <p className="form-submit">
                                <input name="submit" type="submit" id="submit" className="submit" value="Submit" />
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default ProductDetails;
