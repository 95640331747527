import { notify } from 'app/helper';
import { clearError, updateUserDetails, updateUserPassword } from 'features/authSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function AccountDetailsTab() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const updateErrorMessageRef = useRef('');
  const updateErrorMessage = useSelector(state => state.auth.updateErrorMessage);

  const [firstName, setFirstName] = useState(user.firstName || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  
  useEffect(() => {
    // @credit https://stackoverflow.com/a/77228056/8428965
    updateErrorMessageRef.current = updateErrorMessage;
  }, [updateErrorMessage]);

  useEffect(() => {
    if (currentPassword == '' && newPassword == '' && newPasswordConfirm == '') {
      dispatch(clearError());
    }
  }, [currentPassword, newPassword, newPasswordConfirm])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== newPasswordConfirm) {
      alert('New password and confirmation do not match.');
      return;
    }

    dispatch(updateUserDetails({
      email: user.email,
      firstName,
      lastName,
      displayName
    }));

    if (currentPassword != '') {
      dispatch(updateUserPassword({
        email: user.email,
        currentPassword,
        newPassword
      }));
    }

    setTimeout(() => {
      if (!updateErrorMessageRef.current) {
        notify('User details updated');
      }
    }, 100);
  };

  return (
    <div className="tab-pane fade" id="account-details" role="tabpanel">
      <div className="my-account-account-details">
        <p className="status" style={{textAlign: 'center', color: 'red'}}>{updateErrorMessage}</p>
        <form className="edit-account" onSubmit={handleSubmit}>
          <p className="form-row">
            <label htmlFor="account_first_name">First name <span className="required">*</span></label>
            <input type="text" className="input-text" name="account_first_name" onChange={(e) => setFirstName(e.target.value)} value={firstName} required />
          </p>
          <p className="form-row">
            <label>Last name <span className="required">*</span></label>
            <input type="text" className="input-text" name="account_last_name" onChange={(e) => setLastName(e.target.value)} value={lastName} required />
          </p>
          <div className="clear"></div>
          <p className="form-row">
            <label>Display name <span className="required">*</span></label>
            <input type="text" className="input-text" name="account_display_name" onChange={(e) => setDisplayName(e.target.value)} value={displayName} required />
            <span><em>This will be how your name will be displayed in the account section and in reviews</em></span>
          </p>
          <div className="clear"></div>
          <fieldset>
            <legend>Password change</legend>
            <p className="form-row">
              <label>Current password (leave blank to leave unchanged)</label>
              <input type="password" className="input-text" name="password_current" autoComplete="off" onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword} />
            </p>
            <p className="form-row">
              <label>New password (leave blank to leave unchanged)</label>
              <input type="password" className="input-text" name="password_1" autoComplete="off" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
            </p>
            <p className="form-row">
              <label>Confirm new password</label>
              <input type="password" className="input-text" name="password_2" autoComplete="off" onChange={(e) => setNewPasswordConfirm(e.target.value)} value={newPasswordConfirm} />
            </p>
          </fieldset>
          <div className="clear"></div>
          <p className="form-row">
            <button type="submit" className="button" name="save_account_details" value="Save changes">Save changes</button>
          </p>
        </form>
      </div>
    </div>
  );
}

export default AccountDetailsTab;
