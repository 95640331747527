import { notify } from 'app/helper';
import { deleteQuestion } from 'features/questionSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function UserQuestionsTab() {
  const dispatch = useDispatch();
  const questions = useSelector(state => state.questions.questions);

  const handleDelete = (id) => {
    dispatch(deleteQuestion({ id }));
    notify(`Question ID ${id} has been deleted.`);
  }

  return (
    <div className="tab-pane fade" id="questions" role="tabpanel">
      <div className="my-account-orders">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Email</th>
                <th>Question</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              { Object.values(questions).map((question) => {
                return (
                  <tr key={`${question.date}.${question.email}`}>
                    <td>{question.date}</td>
                    <td>{question.name}</td>
                    <td>{question.email}</td>
                    <td>{question.question}</td>
                    <td onClick={() => handleDelete(question.id)} style={{cursor: 'pointer', color: 'black'}}>Delete</td>
                  </tr>
                )
              }) }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default UserQuestionsTab;
