import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, register } from 'features/authSlice';

function LoginModal() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const authenticated = useSelector(state => state.auth.authenticated);
  const user = useSelector(state => state.auth.user);
  const loginErrorMessage = useSelector(state => state.auth.loginErrorMessage);
  const registerErrorMessage = useSelector(state => state.auth.registerErrorMessage);

  const handleLogin = (e) => {
    dispatch(login({ email, password }));
  };

  const handleRegister = (e) => {
    dispatch(register({ email, password, firstName, lastName }));
  };

  useEffect(() => {
    if (authenticated && showModal) {
      setShowModal(false);
    }
  }, [authenticated, showModal]);

  return (
    <div className="login-header">
      {!authenticated && <a className="active-login" onClick={(e) => {e.preventDefault();setShowModal(!showModal)}} href="#">Login</a>}
      {authenticated && <Link className="active-login" to='/my-account'>{`Hello, ${user.displayName}`}</Link>}
      <div className={`form-login-register ${showModal ? 'active' : ''}`}>
        <div className="box-form-login">
          <div className="active-login" onClick={() => setShowModal(!showModal)}></div>
          <div className="box-content">
            <div className="form-login active">
              <form id="login_ajax" method="post" className="login" onSubmit={(e) => e.preventDefault()}>
                <h2>Sign in</h2>
                <p className="status" style={{textAlign: 'center', color: 'red'}}>{loginErrorMessage}</p>
                <div className="content">
                  <div className="username">
                    <input type="email" required="required" className="input-text" placeholder="Your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="password">
                    <input type="password" required="required" className="input-text" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="rememberme-lost">
                    <div className="lost_password">
                      <Link to="/auth/reset">Lost your password?</Link>
                    </div>
                  </div>
                  <div className="button-login">
                    <input type="submit" className="button" value="Login" onClick={handleLogin}/>
                  </div>
                  <div className="button-next-reregister">Create An Account</div>
                </div>
              </form>
            </div>
            <div className="form-register">
              <form method="post" className="register" onSubmit={(e) => e.preventDefault()}>
                <h2>Register</h2>
                <p className="status" style={{textAlign: 'center', color: 'red'}}>{registerErrorMessage}</p>
                <div className="content">
                  <div className="username">
                    <input type="email" required="required" className="input-text" placeholder="Your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>
                  <div className="password">
                    <input type="password" required="required" className="input-text" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className="password">
                    <input type="text" required="required" className="input-text" name="firstName"
                      placeholder="Your first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                  </div>
                  <div className="password">
                    <input type="text" required="required" className="input-text" name="lastName"
                      placeholder="Your last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </div>
                  <div className="button-register">
                    <input type="submit" className="button" name="register" value="Register" onClick={handleRegister}/>
                  </div>
                  <div className="button-next-login">Already have an account</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;
