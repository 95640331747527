import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCompare } from 'features/compareSlice';
import { addToCart } from 'features/cartSlice';
import { notify } from 'app/helper';
import BerryProducts from 'app/BerryProducts';

function CompareModal() {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart.items);
  const products = useSelector(state => state.products.products);
  const compareItems = useSelector(state => state.compare.items);
  const [parsedCompareItems, setParsedCompareItems] = useState([]);

  useEffect(() => {
    if (compareItems.length > 0 && products.length > 0) {
      const parsed = BerryProducts.getProductsBySKUs(compareItems, products);
      setParsedCompareItems(parsed);
    } else {
      setParsedCompareItems([]);
    }
  }, [compareItems, products]);

  const handleAddToCart = (sku, price) => {
    dispatch(addToCart({ sku, qty: 1, price }));
    notify("Product successfully added to cart!");
  };

  const handleRemove = (sku) => {
    dispatch(removeFromCompare({ sku }));
  };

  return (
    <div className="compare-popup">
      <div className="compare-popup-inner">
        <div className="compare-table">
          <div className="compare-table-inner">
            <a href="#" id="compare-table-close" className="compare-table-close">
              <span className="compare-table-close-icon"></span>
            </a>
            <div className="compare-table-items">
              <table id="product-table" className="product-table">
                <thead>
                  <tr>
                    <th>
                      <a href="#" className="compare-table-settings">Settings</a>
                    </th>
                    {parsedCompareItems.map((item, index) => (
                      <th key={index}>
                        <Link to={`/product/${item.slug}`}>{item.name}</Link>
                        <span className="remove" onClick={() => handleRemove(item.sku)}>remove</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="tr-image">
                    <td className="td-label">Image</td>
                    {parsedCompareItems.map((item, index) => (
                      <td key={index}>
                        <Link to={`/product/${item.slug}`}>
                          <img width="600" height="600" style={{maxWidth: '480px'}} src={`/${item.images[0]}`} alt={item.name} />
                        </Link>
                      </td>
                    ))}
                  </tr>
                  <tr className="tr-sku">
                    <td className="td-label">SKU</td>
                    {parsedCompareItems.map((item, index) => (
                      <td key={index}>{item.sku}</td>
                    ))}
                  </tr>
                  <tr className="tr-rating">
                    <td className="td-label">Rating</td>
                    {parsedCompareItems.map((item, index) => (
                      <td key={index}>
                        <div className="star-rating">
                          <span style={{ width: `${item.rating * 20}%` }}></span>
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr className="tr-price">
                    <td className="td-label">Price</td>
                    {parsedCompareItems.map((item, index) => (
                      <td key={index}>
                        {item.price_discounted ? (
                          <>
                            <del><span className="amount">${item.price.toFixed(2)}</span></del>
                            <ins><span className="amount"> ${item.price_discounted.toFixed(2)}</span></ins>
                          </>
                        ) : (
                          <span className="amount">${item.price.toFixed(2)}</span>
                        )}
                      </td>
                    ))}
                  </tr>
                  <tr className="tr-add-to-cart">
                    <td className="td-label">Add to cart</td>
                    {parsedCompareItems.map((item, index) => (
                      <td key={index}>
                        <div data-title="Add to cart">
                          { (cart.some(cart_item => cart_item.sku === item.sku) 
                          ? <Link to="/cart" className="button">View cart</Link> 
                          : <a href="#" className="button" onClick={(e) => {e.preventDefault();handleAddToCart(item.sku, item.price)}}>Add to cart</a>)}
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr className="tr-description">
                    <td className="td-label">Description</td>
                    {parsedCompareItems.map((item, index) => (
                      <td key={index}>{item.description}</td>
                    ))}
                  </tr>
                  <tr className="tr-dimensions">
                    <td className="td-label">Dimensions</td>
                    {parsedCompareItems.map((item, index) => (
                      <td key={index}>{item.dimensions ? item.dimensions : 'N/A'}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompareModal;
