import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist } from 'features/wishlistSlice';
import { notify } from 'app/helper';

function AddToWishlistButton({ sku }) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const wishlist = useSelector(state => state.wishlist.wishlist);

  const isProductInWishlist = wishlist.includes(sku);

  const handleAddToWishlist = () => {
    if (!auth.authenticated) {
      notify("You need to be signed in to do that!");
      return;
    }
    if (isProductInWishlist) {
      openWishlistModal();
    } else {
      dispatch(addToWishlist({ email: auth.user.email, sku: sku }));
      notify("Product added to wishlist!");
    }
  };

  const openWishlistModal = () => {
    window.jQuery(".wishlist-notice").addClass("wishlist-notice-show");
    window.jQuery(".wishlist-popup").addClass("show");
    setTimeout(() => {
      window.jQuery(".wishlist-notice").removeClass("wishlist-notice-show");
    }, 1000);
  }

  return (
    <div className="btn-wishlist" data-title="Wishlist">
      <button
        onClick={handleAddToWishlist}
        className={`product-btn ${isProductInWishlist ? 'added' : ''}`}>
        {isProductInWishlist ? 'In Wishlist' : 'Add to wishlist'}
      </button>
    </div>
  );
}

export default AddToWishlistButton;
