import { useDocumentTitle } from 'hooks/useDocumentTitle';
import PageLayout from 'layouts/PageLayout';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendQuestion } from 'features/questionSlice';
import { notify } from 'app/helper';

function Contact() {
  useDocumentTitle('Contact Us');

  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendQuestion({ name, email, question }));
    notify('Your message has been sent!');
    setName('');
    setEmail('');
    setQuestion('');
  };

  return (
    <PageLayout headerTitle="Contact Us">
      <div className="page-contact">
        <section className="section section-padding">
          <div className="section-container small">
            {/* Block Contact Map */}
            <div className="block block-contact-map">
              <div className="block-widget-wrap">
                <iframe src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" aria-label="London Eye, London, United Kingdom"></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="section section-padding m-b-70">
          <div className="section-container">
            {/* Block Contact Info */}
            <div className="block block-contact-info">
              <div className="block-widget-wrap">
                <div className="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className="svg-icon2 plant" x="0" y="0" viewBox="0 0 512 512" style={{ enableBackground: "new 0 0 512 512" }} xmlSpace="preserve">
                    <g>
                      <path xmlns="http://www.w3.org/2000/svg" d="m320.174 28.058a8.291 8.291 0 0 0 -7.563-4.906h-113.222a8.293 8.293 0 0 0 -7.564 4.907l-66.425 148.875a8.283 8.283 0 0 0 7.564 11.655h77.336v67.765a20.094 20.094 0 1 0 12 0v-67.765h27.7v288.259h-48.441a6 6 0 0 0 0 12h108.882a6 6 0 0 0 0-12h-48.441v-288.259h117.04a8.284 8.284 0 0 0 7.564-11.657zm-103.874 255.567a8.094 8.094 0 1 1 8.094-8.093 8.1 8.1 0 0 1 -8.094 8.093zm-77.61-107.036 63.11-141.437h108.4l63.11 141.437z" fill="" data-original=""></path>
                    </g>
                  </svg>
                </div>
                <div className="info-title">
                  <h2>Need Help?</h2>
                </div>
                <div className="info-items">
                  <div className="row">
                    <div className="col-md-4 sm-m-b-30">
                      <div className="info-item">
                        <div className="item-tilte">
                          <h2>Phone</h2>
                        </div>
                        <div className="item-content">
                          810.222.5439
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 sm-m-b-30">
                      <div className="info-item">
                        <div className="item-tilte">
                          <h2>Customer Service</h2>
                        </div>
                        <div className="item-content">
                          <p>Monday to Friday</p>
                          <p>8:00am - 4:00pm Sydney, NSW time (UTC +10)</p>
                          <p>Saturday and Sunday closed</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="info-item">
                        <div className="item-tilte">
                          <h2>Returns</h2>
                        </div>
                        <div className="item-content small-width">
                          For information on Returns and Refunds, please click <a href="#">here.</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section section-padding contact-background m-b-0">
          <div className="section-container small">
            {/* Block Contact Form */}
            <div className="block block-contact-form">
              <div className="block-widget-wrap">
                <div className="block-title">
                  <h2>Send Us Your Questions!</h2>
                  <div className="sub-title">We'll get back to you within two days.</div>
                </div>
                <div className="block-content">
                  <form onSubmit={handleSubmit} method="post" className="contact-form" noValidate="novalidate">
                    <div className="contact-us-form">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <label className="required">Name</label><br />
                          <span className="form-control-wrap">
                            <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} size="40" className="form-control" aria-required="true" />
                          </span>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <label className="required">Email</label><br />
                          <span className="form-control-wrap">
                            <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} size="40" className="form-control" aria-required="true" />
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <label className="required">Message</label><br />
                          <span className="form-control-wrap">
                            <textarea name="message" cols="40" rows="10" value={question} onChange={(e) => setQuestion(e.target.value)} className="form-control" aria-required="true"></textarea>
                          </span>
                        </div>
                      </div>
                      <div className="form-button">
                        <span>
                          <input type="submit" value="Submit" className="button" />
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </PageLayout>
  );
}

export default Contact;