import React from 'react'
import { Link } from 'react-router-dom'

function SectionBanners2() {
  return (
    <section className="section section-padding m-b-70">
      <div className="section-container">
        {/* Section Banners (Layout 2) */}
        <div className="block block-banners layout-2 banners-effect">
          <div className="section-row">
            <div className="section-column left sm-m-b">
              <div className="section-column-wrap">
                <div className="block-widget-wrap">
                  <div className="block-widget-banner layout-2">
                    <div className="bg-banner">
                      <div className="banner-wrapper banners">
                        <div className="banner-image">
                          <Link to="/shop">
                            <img width="825" height="475" src="media/banner/banner-1.jpg" alt="Banner Image"/>
                          </Link>
                        </div>
                        <div className="banner-wrapper-infor">
                          <div className="info">
                            <div className="content">
                              <Link to="/shop" className="link-title">
                                <h3 className="title-banner">Let the adventure<br/> begin. </h3>
                              </Link>
                              <div className="banner-image-description">
                                Sed lectus. Aliquam lorem ante, <br/>dapibus in, viverra quis, feugiat a, tellus
                              </div>
                              <Link to="/shop" className="button button-outline">SHOP NOW</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-column right">
              <div className="section-column-wrap">
                <div className="block-widget-wrap">
                  <div className="block-widget-banner layout-3">
                    <div className="bg-banner">
                      <div className="banner-wrapper banners">
                        <div className="banner-image">
                          <Link to="/shop">
                            <img width="571" height="475" src="media/banner/banner-2.jpg" alt="Banner Image"/>
                          </Link>
                        </div>
                        <div className="banner-wrapper-infor">
                          <div className="info">
                            <div className="content">
                              <Link to="/shop" className="link-title">
                                <h3 className="title-banner">UP TO <span>20% OFF</span></h3>
                              </Link>
                              <div className="banner-image-description">
                                Don't miss savings on bedroom, living,
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionBanners2;