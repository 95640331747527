import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('wishlist', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('wishlist');
  return data ? JSON.parse(data) : defaultValue;
};

const initialwishlist = {
  wishlist: [],
  allWishlist: {
    'johndoe@example.com': ['dt-3500', 'pdtr-3250'],
  }
};

const initialState = loadFromLocalStorage(initialwishlist);

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishlistByEmail: (state, action) => {
      const email = action.payload;
      if (email && state.allWishlist[email]) {
        state.wishlist = state.allWishlist[email];
      }
      updateLocalStorage(state);
    },
    addToWishlist: (state, action) => {
      const { email, sku } = action.payload;
      if (!state.allWishlist[email]) {
        state.allWishlist[email] = [];
      }
      if (!state.allWishlist[email].includes(sku)) {
        state.allWishlist[email].push(sku);
        state.wishlist = state.allWishlist[email];
      }
      updateLocalStorage(state);
    },
    removeFromWishlist: (state, action) => {
      const { email, sku } = action.payload;
      if (state.allWishlist[email]) {
        state.allWishlist[email] = state.allWishlist[email].filter(i => i.toLowerCase() !== sku.toLowerCase());
        state.wishlist = state.allWishlist[email];
        updateLocalStorage(state);
      }
    },
    clearWishlist: (state) => {
      state.wishlist = [];
      updateLocalStorage(state);
    }
  }
});

export const { setWishlistByEmail, addToWishlist, removeFromWishlist, clearWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
