import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function OrderTab() {
  const orders = useSelector(state => state.orders.orders);

  return (
    <div className="tab-pane fade" id="orders" role="tabpanel">
      <div className="my-account-orders">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Status</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              { [...orders].map((order) => {
                return (
                  <tr key={order.id}>
                    <td>#{order.id}</td>
                    <td>{order.date}</td>
                    <td>{order.status}</td>
                    <td>${order.total} for {order.items.length} item</td>
                    <td><Link to={`/orders/${order.id}/details`} className="btn-small d-block">View</Link></td>
                  </tr>
                )
              }) }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default OrderTab;