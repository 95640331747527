import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCategoryFilter, setSizeFilter, setBrandFilter, setMinPrice, setMaxPrice } from 'features/filtersSlice';
import BerryProducts from 'app/BerryProducts';

function Filters() {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.filters);
  const products = useSelector(state => state.products.products);
  const filtersMetadata = BerryProducts.getFiltersMetadata(products);

  const handleCategoryChange = (category) => {
    dispatch(setCategoryFilter(category));
  };

  const handleMinPriceChange = (price) => {
    dispatch(setMinPrice(price));
  };

  const handleMaxPriceChange = (price) => {
    dispatch(setMaxPrice(price));
  };

  const handleSizeChange = (size) => {
    dispatch(setSizeFilter(size));
  };

  const handleBrandChange = (brand) => {
    dispatch(setBrandFilter(brand));
  };

  return (
    <div className="col-xl-3 col-lg-3 col-md-12 col-12 sidebar left-sidebar md-b-50">
      <div className="block block-product-cats">
        <div className="block-title">
          <h2>Categories</h2>
        </div>
        <div className="block-content">
          <div className="product-cats-list">
            <ul>
              {filtersMetadata.categories.map((category) => (
                <li key={category.category} className={filters.category === category.category ? 'current' : ''}>
                  <a href="#" onClick={() => handleCategoryChange(category.category)}>{category.category} <span className="count">{category.count}</span></a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="block block-product-filter">
        <div className="block-title">
          <h2>Price</h2>
        </div>
        <div className="block-content">
          <input type="number" placeholder="Lowest Price" name="priceMin" min={filtersMetadata.minPrice} max={filtersMetadata.maxPrice} value={filters.minPrice || filtersMetadata.minPrice} onChange={(e) => { handleMinPriceChange(e.target.value) }} />
          <span> - </span>
          <input type="number" placeholder="Highest Price" name="priceMax" max={filtersMetadata.maxPrice} min="0" value={filters.maxPrice || filtersMetadata.maxPrice} onChange={(e) => { handleMaxPriceChange(e.target.value) }} />
        </div>
      </div>

      <div className="block block-product-filter clearfix">
        <div className="block-title d-flex align-items-center">
          <h2>Size</h2>
          <p className="ml-auto cursor-pointer" onClick={() => handleSizeChange(null)}>Clear</p>
        </div>
        <div className="block-content">
          <ul className="filter-items text">
            {filtersMetadata.sizes.map((size) => (
              <li key={size}>
                <span onClick={() => handleSizeChange(size)}>{size}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="block block-product-filter clearfix">
        <div className="block-title d-flex align-items-center">
          <h2>Brands</h2>
          <p className="ml-auto cursor-pointer" onClick={() => handleBrandChange(null)}>Clear</p>
        </div>
        <div className="block-content">
          <ul className="filter-items image">
            {filtersMetadata.brands.map((brand) => (
              <li key={brand}>
                <span onClick={() => handleBrandChange(brand)}>
                  <img src={`media/brand/${brand.charCodeAt(0) - 64}.jpg`} alt="Brand" />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Filters;
