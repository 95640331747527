import React from 'react';
import PageLayout from 'layouts/PageLayout';
import SliderComponent from 'components/Home/SliderComponent';
import SectionBanners from 'components/Home/SectionBanners';
import SectionBanners2 from 'components/Home/SectionBanners2';
import SectionVideo from 'components/Home/SectionVideo';
import SectionFeature from 'components/Home/SectionFeature';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

function Home() {
  useDocumentTitle('Home');

  return (
    <PageLayout absoluteHeader={true}>
      <SliderComponent />
      <SectionBanners />
      <SectionBanners2 />
      <SectionVideo />
      <SectionFeature />
    </PageLayout>
  )
}

export default Home;