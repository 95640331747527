import React from 'react'
import { Link } from 'react-router-dom';
import LoginModal from './Header/LoginModal';
import SearchModal from './Header/SearchModal';
import WishlistModal from './Header/WishlistIcon';
import CartDropdown from './Header/CartDropdown';
import { useSelector } from 'react-redux';

function Header({ absoluteHeader = false }) {
  const authenticated = useSelector(state => state.auth.authenticated);

  return (
    <header id="site-header" className={`site-header header-v1 ${absoluteHeader ? 'absolute' : 'border-bottom'}`}>
      {/* Header Mobile */}
      <div className="header-mobile">
        <div className="section-padding">
          <div className="section-container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-3 col-3 header-left">
                <div className="navbar-header">
                  <button type="button" id="show-megamenu" className="navbar-toggle"></button>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 header-center">
                <div className="site-logo">
                  <Link to="/">
                    <img width="400" height="79" src="/media/logo.png" alt="Berry Furniture Store Logo" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-mobile-fixed">
          {/* <!-- Shop --> */}
          <div className="shop-page">
            <a href="shop-grid-left.html"><i className="wpb-icon-shop"></i></a>
          </div>

          {/* <!-- Login --> */}
          <div className="my-account">
            <div className="login-header">
              <a href="page-my-account.html"><i className="wpb-icon-user"></i></a>
            </div>
          </div>

          {/* <!-- Wishlist --> */}
          <div className="wishlist-box">
            <a href="shop-wishlist.html"><i className="wpb-icon-heart"></i></a>
          </div>
        </div>
      </div>

      {/* Header Desktop */}
      <div className="header-desktop">
        <div className="header-wrapper">
          <div className="section-padding">
            <div className="section-container p-l-r">
              <div className="row">
                {/* Header Left */}
                <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 header-left">
                  <div className="site-logo">
                    <Link to="/">
                      <img width="400" height="79" src="/media/logo.png" alt="Berry Furniture Store Logo" />
                    </Link>
                  </div>
                </div>

                {/* Header Center */}
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center header-center">
                  <div className="site-navigation">
                    <nav id="main-navigation">
                      <ul id="menu-main-menu" className="menu">
                        <li className="level-0 menu-item">
                          <Link to="/"><span className="menu-item-text">Home</span></Link>
                        </li>
                        <li className="level-0 menu-item menu-item-has-children">
                          <Link to="/shop"><span className="menu-item-text">Shop</span></Link>
                          <ul className="sub-menu">
                            <li>
                              <Link to="/shop"><span className="menu-item-text">All Products</span></Link>
                            </li>
                            <li>
                              <Link to="/shop"><span className="menu-item-text">Products on Sale</span></Link>
                            </li>
                          </ul>
                        </li>
                        <li className="level-0 menu-item">
                          <Link to="/contact"><span className="menu-item-text">Contact</span></Link>
                        </li>
                        { authenticated && 
                        <li className="level-0 menu-item">
                          <Link to="/my-account"><span className="menu-item-text">My Account</span></Link>
                        </li> }
                      </ul>
                    </nav>
                  </div>
                </div>

                {/* Header Right */}
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 header-right">
                  <div className="header-page-link">
                    <LoginModal />
                    <WishlistModal />
                    <CartDropdown />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
