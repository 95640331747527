import { configureStore } from '@reduxjs/toolkit';
import wishlistReducer from 'features/wishlistSlice';
import productsReducer from 'features/productsSlice';
import authReducer from 'features/authSlice';
import ordersReducer from 'features/ordersSlice';
import cartReducer from 'features/cartSlice';
import addressReducer from 'features/adressSlice';
import questionsReducer from 'features/questionSlice';
import compareReducer from 'features/compareSlice';
import quickviewReducer from 'features/quickviewSlice';
import newsletterReducer from 'features/newsletterSlice';
import filterReducer from 'features/filtersSlice';

const store = configureStore({
  reducer: {
    wishlist: wishlistReducer,
    products: productsReducer,
    auth: authReducer,
    orders: ordersReducer,
    cart: cartReducer,
    address: addressReducer,
    questions: questionsReducer,
    compare: compareReducer,
    quickview: quickviewReducer,
    newsletter: newsletterReducer,
    filters: filterReducer,
  },
});

export default store;