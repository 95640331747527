import React from 'react'
import Header from 'components/Header';
import Footer from 'components/Footer';
import PageContentWrapper from 'components/PageContentWrapper';
import CommonModalComponents from 'components/CommonModalComponents';

function PageLayout({ headerTitle, absoluteHeader = false, children }) {
  return (
    <>
      <Header absoluteHeader={!!headerTitle || absoluteHeader} />
      <PageContentWrapper headerTitle={headerTitle}>
        {children}
      </PageContentWrapper>
      <Footer />
      <CommonModalComponents />
    </>
  )
}

export default PageLayout;