import { createSlice } from '@reduxjs/toolkit';

const generateToken = () => Math.random().toString(36).substr(2, 8);

const updateLocalStorage = (data) => {
  localStorage.setItem('questions', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('questions');
  return data ? JSON.parse(data) : defaultValue;
};

const initialQuestions = {
  questions: {
    "questionID1": {
      id: 'questionID1',
      date: '7/4/2024 4:05:42 AM',
      name: "John Doe",
      email: "johndoe@gmail.com",
      question: "What is the return policy?"
    },
    "questionID2": {
      id: 'questionID2',
      date: '6/4/2024 3:04:00 AM',
      name: "Jane Smith",
      email: "janesmith@gmail.com",
      question: "How can I track my order?"
    },
    "questionID3": {
      id: 'questionID3',
      date: '5/4/2024 2:03:20 AM',
      name: "Alice Brown",
      email: "alicebrown@gmail.com",
      question: "Do you offer gift wrapping services?"
    }
  },
};

const initialState = loadFromLocalStorage(initialQuestions);

const questionSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    sendQuestion: (state, action) => {
      const { name, email, question } = action.payload;
      const id = generateToken();
      state.questions[id] = { id, name, email, question, date: (new Date().toLocaleString().replace(',', '')) };
      updateLocalStorage(state);
    },
    deleteQuestion: (state, action) => {
      const { id } = action.payload;
      delete state.questions[id];
      updateLocalStorage(state);
    }
  }
});

export const { sendQuestion, deleteQuestion } = questionSlice.actions;
export default questionSlice.reducer;
