import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('compare', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('compare');
  return data ? JSON.parse(data) : defaultValue;
};

const initialCompareItems = {
  items: [],
};

const initialState = loadFromLocalStorage(initialCompareItems);

const compareSlice = createSlice({
  name: 'compare',
  initialState,
  reducers: {
    addToCompare: (state, action) => {
      const { sku } = action.payload;
      if (state.items.includes(sku)) {
        return;
      }
      if (state.items.length >= 3) {
        state.items.shift(); // Remove the oldest item if there are already 3 items
      }
      state.items.push(sku);
      updateLocalStorage(state);
    },
    removeFromCompare: (state, action) => {
      const { sku } = action.payload;
      state.items = state.items.filter(item => item.toLowerCase() !== sku.toLowerCase());
      updateLocalStorage(state);
    },
  },
});

export const { addToCompare, removeFromCompare } = compareSlice.actions;
export default compareSlice.reducer;
