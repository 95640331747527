import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import PageLayout from 'layouts/PageLayout';
import { requestResetToken } from 'features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { notify } from 'app/helper';

function ResetRequest() {
  useDocumentTitle('Reset Password');
  
  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.auth.authenticated);
  const resetPasswordToken = useSelector(state => state.auth.resetPasswordToken);
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(requestResetToken(email));
    notify('Successfully requested a password reset!<br>We\'ll send an email to the address if an account is tied to it.');
  };

  if (authenticated) {
    return <Navigate to="/" />;
  }

  return (
    <PageLayout headerTitle="Reset Password">
      <div className="section-padding">
        <div className="section-container p-l-r">
          <div className="page-login-register">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="box-form-login">
                  <h2>Reset Password</h2>
                  <div className="box-content">
                    <div className="form-login">
                      <form onSubmit={handleSubmit} className="login">
                        <div className="username">
                          <label htmlFor="email">Email address <span className="required">*</span></label>
                          <input type="text" className="input-text" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className="button-login">
                          <input type="submit" className="button" name="reset" value="Reset Password" />
                        </div>
                        <div className="button-login">
                          <Link to="/auth/register">Create an account</Link>
                        </div>
                        { !!resetPasswordToken && <p style={{textAlign: 'center'}}>Reset Token (because this is just for demo): <Link to={`/auth/reset/${resetPasswordToken}`}>{resetPasswordToken}</Link></p> }
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default ResetRequest;
