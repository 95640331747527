import React from 'react'
import { Link } from 'react-router-dom'

function SliderComponent() {
  return (
    <section className="section">
      {/* Block Sliders */}
      <div className="block block-sliders">
        <div className="slick-sliders" data-autoplay="true" data-dots="true" data-nav="false" data-columns4="1"
          data-columns3="1" data-columns2="1" data-columns1="1" data-columns1440="1" data-columns="1">
          <div className="item slick-slide">
            <div className="item-content">
              <div className="content-image">
                <img width="1920" height="1080" src="media/slider/1.jpg" alt="Image Slider" />
              </div>
              <div className="section-padding">
                <div className="section-container">
                  <div className="item-info horizontal-start vertical-middle">
                    <div className="content">
                      <div className="subtitle-slider">20%OFF.END MONDAY</div>
                      <h2 className="title-slider">Chair Collection</h2>
                      <div className="description-slider">Save up to $500 on outdoor packages </div>
                      <Link to="/shop" className="button-slider button-white">SHOP NOW</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item slick-slide">
            <div className="item-content">
              <div className="content-image">
                <img width="1920" height="1080" src="media/slider/2.jpg" alt="Image Slider" />
              </div>
              <div className="section-padding">
                <div className="section-container">
                  <div className="item-info horizontal-start vertical-middle">
                    <div className="content">
                      <div className="subtitle-slider">20%OFF.END MONDAY</div>
                      <h2 className="title-slider">Interior lighting</h2>
                      <div className="description-slider">Save up to $500 on outdoor packages </div>
                      <a className="button-slider button-white" href="shop-grid-left.html">SHOP NOW</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item slick-slide">
            <div className="item-content">
              <div className="content-image">
                <img width="1920" height="1080" src="media/slider/3.jpg" alt="Image Slider" />
              </div>
              <div className="section-padding">
                <div className="section-container">
                  <div className="item-info horizontal-start vertical-middle">
                    <div className="content">
                      <div className="subtitle-slider">20%OFF.END MONDAY</div>
                      <h2 className="title-slider">Home office</h2>
                      <div className="description-slider">Save up to $500 on outdoor packages </div>
                      <a className="button-slider button-white" href="shop-grid-left.html">SHOP NOW</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SliderComponent