import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAddress } from 'features/adressSlice';

function AddressTab() {
  const email = useSelector(state => state.auth.user.email);
  const { billing, shipping } = useSelector(state => state.address.address);
  const [showBillingEdit, setShowBillingEdit] = useState(false);
  const [showShippingEdit, setShowShippingEdit] = useState(false);
  const [billingAddress, setBillingAddress] = useState(billing || {});
  const [shippingAddress, setShippingAddress] = useState(shipping || {});

  const dispatch = useDispatch();

  const handleBillingChange = (e) => {
    setBillingAddress({
      ...billingAddress,
      [e.target.name]: e.target.value,
    });
  };

  const handleShippingChange = (e) => {
    setShippingAddress({
      ...shippingAddress,
      [e.target.name]: e.target.value,
    });
  };

  const handleBillingSave = (e) => {
    e.preventDefault();
    dispatch(updateAddress({ email: email, type: 'billing', data: billingAddress }));
    setShowBillingEdit(false);
  };

  const handleShippingSave = (e) => {
    e.preventDefault();
    dispatch(updateAddress({ email: email, type: 'shipping', data: shippingAddress }));
    setShowShippingEdit(false);
  };

  return (
    <div className="tab-pane fade" id="addresses" role="tabpanel">
      <div className="my-account-addresses">
        <p>
          The following addresses will be used on the checkout page by default.
        </p>
        <div className="addresses">
          <div className="addresses-col">
            <header className="col-title">
              <h3>Billing address</h3>
              <a href="#" className="edit" onClick={(e) => {e.preventDefault();setShowBillingEdit(!showBillingEdit)}}>
                {showBillingEdit ? 'Cancel' : 'Edit'}
              </a>
            </header>
            {showBillingEdit ? (
              <div className="shop-checkout px-3 py-3 border border-top-0">
                <form className="checkout" autoComplete="off" onSubmit={handleBillingSave}>
                  <div className="billing-fields-wrapper">
                    <p className="form-row address-field validate-required form-row-wide">
                      <label>Street address <span className="required" title="required">*</span></label>
                      <span className="input-wrapper">
                        <input type="text" className="input-text" name="line1" placeholder="House number and street name" value={billingAddress.line1 || ''} onChange={handleBillingChange} required />
                      </span>
                    </p>
                    <p className="form-row address-field form-row-wide">
                      <label>Apartment, suite, unit, etc.&nbsp;<span className="optional">(optional)</span></label>
                      <span className="input-wrapper">
                        <input type="text" className="input-text" name="line2" placeholder="Apartment, suite, unit, etc. (optional)" value={billingAddress.line2 || ''} onChange={handleBillingChange} />
                      </span>
                    </p>
                    <p className="form-row address-field validate-required form-row-wide">
                      <label>Town / City and ZIP code <span className="required" title="required">*</span></label>
                      <span className="input-wrapper">
                        <input type="text" className="input-text" name="line3" value={billingAddress.line3 || ''} onChange={handleBillingChange} required />
                      </span>
                    </p>
                    <p className="form-row form-row-wide validate-required validate-phone">
                      <label>Phone <span className="required" title="required">*</span></label>
                      <span className="input-wrapper">
                        <input type="tel" className="input-text" name="line4" value={billingAddress.line4 || ''} onChange={handleBillingChange} required />
                      </span>
                    </p>
                    <button type="submit" className="button alt">Save</button>
                  </div>
                </form>
              </div>
            ) : (
              <address>
                {billing ? (
                  <>
                    {billing.line1}<br />
                    {billing.line2}<br />
                    {billing.line3}<br />
                    {billing.line4}<br />
                  </>
                ) : <i>Address not set</i>}
              </address>
            )}
          </div>
          <div className="addresses-col">
            <header className="col-title">
              <h3>Shipping address</h3>
              <a href="#" className="edit" onClick={(e) => {e.preventDefault();setShowShippingEdit(!showShippingEdit)}}>
                {showShippingEdit ? 'Cancel' : 'Edit'}
              </a>
            </header>
            {showShippingEdit ? (
              <div className="shop-checkout px-3 py-3 border border-top-0">
                <form className="checkout" autoComplete="off" onSubmit={handleShippingSave}>
                  <div className="shipping-fields-wrapper">
                    <p className="form-row address-field validate-required form-row-wide">
                      <label>Street address <span className="required" title="required">*</span></label>
                      <span className="input-wrapper">
                        <input type="text" className="input-text" name="line1" placeholder="House number and street name" value={shippingAddress.line1 || ''} onChange={handleShippingChange} required />
                      </span>
                    </p>
                    <p className="form-row address-field form-row-wide">
                      <label>Apartment, suite, unit, etc.&nbsp;<span className="optional">(optional)</span></label>
                      <span className="input-wrapper">
                        <input type="text" className="input-text" name="line2" placeholder="Apartment, suite, unit, etc. (optional)" value={shippingAddress.line2 || ''} onChange={handleShippingChange} />
                      </span>
                    </p>
                    <p className="form-row address-field validate-required form-row-wide">
                      <label>Town / City and ZIP code <span className="required" title="required">*</span></label>
                      <span className="input-wrapper">
                        <input type="text" className="input-text" name="line3" value={shippingAddress.line3 || ''} onChange={handleShippingChange} required />
                      </span>
                    </p>
                    <p className="form-row form-row-wide validate-required validate-phone">
                      <label>Phone <span className="required" title="required">*</span></label>
                      <span className="input-wrapper">
                        <input type="tel" className="input-text" name="line4" value={shippingAddress.line4 || ''} onChange={handleShippingChange} required />
                      </span>
                    </p>
                    <button type="submit" className="button alt">Save</button>
                  </div>
                </form>
              </div>
            ) : (
              <address>
                {shipping ? (
                  <>
                    {shipping.line1}<br />
                    {shipping.line2}<br />
                    {shipping.line3}<br />
                    {shipping.line4}<br />
                  </>
                ) : <i>Address not set</i>}
              </address>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressTab;
