import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('cart', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('cart');
  return data ? JSON.parse(data) : defaultValue;
};

const initialCart = {
  items: [],
  totalPrice: 0,
  minimumFreeShipping: 200,
};

const initialState = loadFromLocalStorage(initialCart);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { sku, qty, price } = action.payload;
      const existingItem = state.items.find(item => item.sku === sku);
      
      if (existingItem) {
        existingItem.qty += qty;
      } else {
        state.items.push({ sku, qty, price });
      }

      state.totalPrice = state.items.reduce((total, item) => total + item.price * item.qty, 0);
      updateLocalStorage(state);
    },
    removeFromCart: (state, action) => {
      const { sku } = action.payload;
      state.items = state.items.filter(item => item.sku !== sku);
      state.totalPrice = state.items.reduce((total, item) => total + item.price * item.qty, 0);
      updateLocalStorage(state);
    },
    updateCartItemQty: (state, action) => {
      const { sku, qty } = action.payload;
      const existingItem = state.items.find(item => item.sku === sku);
      
      if (existingItem) {
        if (qty < 0) {
          qty = 1;
        }
        existingItem.qty = qty;
      }

      state.totalPrice = state.items.reduce((total, item) => total + item.price * item.qty, 0);
      updateLocalStorage(state);
    },
  },
});

export const { addToCart, removeFromCart, updateCartItemQty } = cartSlice.actions;
export default cartSlice.reducer;
