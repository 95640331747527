import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCompare } from 'features/compareSlice';

function CompareItem({ product }) {
  const dispatch = useDispatch();

  const openCompareModal = () => {
    window.jQuery(".compare-popup").addClass("active");
  };

  const handleAddToCompare = (e) => {
    e.preventDefault();
    dispatch(addToCompare(product));
    openCompareModal();
  };

  return (
    <div className="btn-compare" data-title="Compare">
      <button onClick={handleAddToCompare} className="product-btn">Compare</button>
    </div>
  );
}

export default CompareItem;
