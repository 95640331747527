import React from 'react'
import { Link } from 'react-router-dom'

function SectionBanners() {
  return (
    <section className="section section-padding m-b-60">
      <div className="section-container">
        {/* Section Banners (Layout 1) */}
        <div className="block block-banners layout-1 banners-effect">
          <div className="section-row">
            <div className="section-column left sm-m-b">
              <div className="section-column-wrap">
                <div className="block-widget-wrap">
                  <div className="block-widget-banner layout-1">
                    <div className="bg-banner">
                      <div className="banner-wrapper banners">
                        <div className="banner-image">
                          <Link to="/shop">
                            <img width="571" height="622" src="media/banner/product-cat-1.jpg" alt="Banner Image"/>
                          </Link>
                        </div>
                        <div className="banner-wrapper-infor">
                          <div className="info">
                            <div className="content">
                              <Link to="/shop" className="button button-white" >Furniture</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-column right">
              <div className="section-column-wrap">
                <div className="block-widget-wrap p-0">
                  <div className="block-section m-b-15">
                    <div className="section-container">
                      <div className="section-row">
                        <div className="section-column column-50 sm-m-b">
                          <div className="block-widget-wrap">
                            <div className="block-widget-banner layout-1">
                              <div className="bg-banner">
                                <div className="banner-wrapper banners">
                                  <div className="banner-image">
                                    <Link to="/shop">
                                      <img width="406" height="304" src="media/banner/product-cat-2.jpg"
                                        alt="Banner Image"/>
                                    </Link>
                                  </div>
                                  <div className="banner-wrapper-infor">
                                    <div className="info">
                                      <div className="content">
                                        <Link to="/shop" className="button button-white">Lighting</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="section-column column-50">
                          <div className="block-widget-wrap">
                            <div className="block-widget-banner layout-1">
                              <div className="bg-banner">
                                <div className="banner-wrapper banners">
                                  <div className="banner-image">
                                    <Link to="/shop">
                                      <img width="406" height="304" src="media/banner/product-cat-3.jpg"
                                        alt="Banner Image"/>
                                    </Link>
                                  </div>
                                  <div className="banner-wrapper-infor">
                                    <div className="info">
                                      <div className="content">
                                        <Link to="/shop" className="button button-white">Modern</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="block-section">
                    <div className="section-container">
                      <div className="section-row">
                        <div className="section-column">
                          <div className="block-widget-wrap">
                            <div className="block-widget-banner layout-1">
                              <div className="bg-banner">
                                <div className="banner-wrapper banners">
                                  <div className="banner-image">
                                    <Link to="/shop">
                                      <img width="406" height="304" src="media/banner/product-cat-4.jpg"
                                        alt="Banner Image"/>
                                    </Link>
                                  </div>
                                  <div className="banner-wrapper-infor">
                                    <div className="info">
                                      <div className="content">
                                        <Link to="/shop" className="button button-white">Accessories</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionBanners;