/**
 * Omits specified keys from object
 * @param {Object} obj 
 * @param {Array} keysToOmit 
 * @returns Object
 */
export function omitKeys(obj, keysToOmit) {
    return Object.keys(obj).reduce((acc, key) => {
        if (!keysToOmit.includes(key)) {
            acc[key] = obj[key];
        }
        return acc;
    }, {});
};

/**
 * Show notification
 * @param {String} message
 * @returns undefined
 */
export function notify(message) {
    window.jQuery("body").append(`<div class="cart-product-added"><div class="added-message">${message}</div>`);
    setTimeout(function () {
        window.jQuery(".cart-product-added").remove();
    }, 2000);
    return;
}