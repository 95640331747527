import PageLayout from 'layouts/PageLayout';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const OrderDetails = () => {
  const { orderId } = useParams();
  const order = useSelector(state =>
    state.orders.orders.find(order => order.id === parseInt(orderId))
  );

  if (!order) {
    return <div className="container mt-5 text-center">Order not found</div>;
  }

  return (
    <PageLayout headerTitle='Order Details'>
      <div className="container mt-5">
      <div className="card">
        <div className="card-header">
          <h1>Order Details</h1>
        </div>
        <div className="card-body">
          <p><strong>Order ID:</strong> {order.id}</p>
          <p><strong>User:</strong> {order.user}</p>
          <p><strong>Date:</strong> {order.date}</p>
          <p><strong>Status:</strong> {order.status}</p>

          <h2 className="mt-4">Items</h2>
          <ul className="list-group">
            {order.items.map((item, index) => (
              <li key={index} className="list-group-item">
                <p><strong>SKU:</strong> {item.sku}</p>
                <p><strong>Price:</strong> ${item.price}</p>
                <p><strong>Variant:</strong> {item.variant}</p>
                <p><strong>Quantity:</strong> {item.qty}</p>
              </li>
            ))}
          </ul>

          <h2 className="mt-4">Delivery Address</h2>
          <p>{order.deliver_to.line1}</p>
          <p>{order.deliver_to.line2}</p>
          <p>{order.deliver_to.line3}</p>
          <p>{order.deliver_to.line4}</p>

          <h2 className="mt-4">Billing Address</h2>
          <p>{order.billed_to.line1}</p>
          <p>{order.billed_to.line2}</p>
          <p>{order.billed_to.line3}</p>
          <p>{order.billed_to.line4}</p>

          <p className="mt-4"><strong>Shipping:</strong> ${order.shipping}</p>
          <p><strong>Total:</strong> ${order.total}</p>
        </div>
      </div>
    </div>
    </PageLayout>
  );
};

export default OrderDetails;
