import PageLayout from 'layouts/PageLayout'
import React, { useEffect, useState } from 'react'
import { useDocumentTitle } from 'hooks/useDocumentTitle'
import { useDispatch, useSelector } from 'react-redux';
import BerryProducts from 'app/BerryProducts';
import Filters from './Filters';
import ProductCard from './ProductCard';

function Shop() {
  useDocumentTitle('Shop');

  const products = useSelector(state => state.products.products);
  const filters = useSelector(state => state.filters);
  const [parsedProducts, setParsedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortMethod, setSortMethod] = useState('default');
  const productsPerPage = 6;

  useEffect(() => {
    if (products.length > 0) {
      const parsed = BerryProducts.parseProducts(products);
      setParsedProducts(parsed);
    } else {
      setParsedProducts([]);
    }
  }, [products]);

  const sortProducts = (products, method) => {
    switch (method) {
      case 'discount':
        return [...products].sort((a, b) => b.discount - a.discount);
      case 'rating':
        return [...products].sort((a, b) => b.rating - a.rating);
      case 'priceLowToHigh':
        return [...products].sort((a, b) => a.price - b.price);
      case 'priceHighToLow':
        return [...products].sort((a, b) => b.price - a.price);
      default:
        return products;
    }
  };

  const filterProducts = (products) => {
    return products.filter(product => {
      let price = product.price_discounted ?? product.price;
      let matchesCategory = filters.category ? product.category === filters.category : true,
        matchesPrice = ((filters.minPrice) ? price >= filters.minPrice : true) 
          && ((filters.maxPrice) ? price <= filters.maxPrice : true),
        matchesSize = filters.size ? product.variations.size.includes(filters.size) : true,
        matchesBrand = filters.brand ? product.brand === filters.brand : true;
      return matchesCategory && matchesPrice && matchesSize && matchesBrand;
    });
  };

  const filteredProducts = filterProducts(parsedProducts);
  const sortedProducts = sortProducts(filteredProducts, sortMethod);

  // For pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSortChange = (method) => {
    setSortMethod(method);
    setCurrentPage(1); // Reset to the first page when sorting changes
  };

  return (
    <PageLayout headerTitle="All Products">
      <div className="section-padding">
        <div className="section-container p-l-r">
          <div className="row">
            <Filters />
            <div className="col-xl-9 col-lg-9 col-md-12 col-12">
              <div className="products-topbar clearfix">
                <div className="products-topbar-left">
                  <div className="products-count">
                    Showing {currentProducts.length} of {filteredProducts.length} results
                  </div>
                </div>
                <div className="products-topbar-right">
                  <div className="products-sort dropdown">
                    <span className="sort-toggle dropdown-toggle" data-toggle="dropdown" aria-expanded="true">Default sorting</span>
                    <ul className="sort-list dropdown-menu" x-placement="bottom-start">
                      <li className={sortMethod === 'default' ? 'active' : ''} onClick={(e) => {e.preventDefault();handleSortChange('default')}}><a href="#">Default sorting</a></li>
                      <li className={sortMethod === 'discount' ? 'active' : ''} onClick={(e) => {e.preventDefault();handleSortChange('discount')}}><a href="#">Sort by discount</a></li>
                      <li className={sortMethod === 'rating' ? 'active' : ''} onClick={(e) => {e.preventDefault();handleSortChange('rating')}}><a href="#">Sort by average rating</a></li>
                      <li className={sortMethod === 'priceLowToHigh' ? 'active' : ''} onClick={(e) => {e.preventDefault();handleSortChange('priceLowToHigh')}}><a href="#">Sort by price: low to high</a></li>
                      <li className={sortMethod === 'priceHighToLow' ? 'active' : ''} onClick={(e) => {e.preventDefault();handleSortChange('priceHighToLow')}}><a href="#">Sort by price: high to low</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="tab-content">
                <div className="tab-pane fade show active" id="layout-grid" role="tabpanel">
                  <div className="products-list grid">
                    <div className="row">
                      {currentProducts.map(product => (
                        <ProductCard key={product.sku} product={product} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <nav className="pagination">
                <ul className="page-numbers">
                  <li className={`page-numbers ${currentPage === 1 ? 'disabled' : ''}`} onClick={(e) => { e.preventDefault(); paginate(currentPage - 1) }}>
                    <a className="prev page-numbers" href="#">Previous</a>
                  </li>
                  {[...Array(totalPages)].map((_, i) => (
                    <li key={i + 1} className={`page-numbers ${currentPage === i + 1 ? 'current' : ''}`} onClick={(e) => { e.preventDefault(); paginate(i + 1) }}>
                      <a className="page-numbers" href="#">{i + 1}</a>
                    </li>
                  ))}
                  <li className={`page-numbers ${currentPage === totalPages ? 'disabled' : ''}`} onClick={(e) => { e.preventDefault(); paginate(currentPage + 1) }}>
                    <a className="next page-numbers" href="#">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Shop;
