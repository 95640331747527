import { useDocumentTitle } from 'hooks/useDocumentTitle';
import PageLayout from 'layouts/PageLayout';
import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  useDocumentTitle('Error 404: Not Found');
  
  return (
    <PageLayout>
      <div className="section-padding">
        <div className="section-container p-l-r">
          <div className="page-404">
            <div className="content-page-404">
              <div className="title-error">
                404
              </div>
              <div className="sub-title">
                Oops! That page can't be found.
              </div>
              <div className="sub-error">
                We're really sorry but we can't seem to find the page you were looking for.
              </div>
              <Link className="button" to="/">
                Back The Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default NotFound;