import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import PageLayout from 'layouts/PageLayout';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import BerryProducts from 'app/BerryProducts';
import { addToCart } from 'features/cartSlice';
import { removeFromWishlist } from 'features/wishlistSlice';

function Wishlist() {
  useDocumentTitle('Wishlist');

  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.auth.authenticated);
  const user = useSelector(state => state.auth.user);
  const products = useSelector(state => state.products.products);
  const wishlist = useSelector(state => state.wishlist.wishlist);
  const [parsedWishlist, setParsedWishlist] = useState([]);

  useEffect(() => {
    if (wishlist.length > 0 && products.length > 0) {
      const parsed = BerryProducts.getProductsBySKUs(wishlist, products);
      setParsedWishlist(parsed);
    } else {
      setParsedWishlist([]);
    }
  }, [wishlist, products]);

  if (!authenticated) {
    return <Navigate to="/auth/login" />;
  }

  const handleAddToCart = (sku, price) => {
    dispatch(addToCart({ sku, qty: 1, price }));
  };

  const handleRemove = (sku) => {
    const email = user.email;
    dispatch(removeFromWishlist({ email, sku }));
  };

  return (
    <PageLayout headerTitle="Wishlist">
      <div id="content" className="site-content" role="main">
        <div className="section-padding">
          <div className="section-container p-l-r">
            <div className="shop-wishlist">
              <table className="wishlist-items">
                <tbody>
                  {parsedWishlist.length === 0 ? (
                    <tr>
                      <td colSpan="4">Your wishlist is empty. <Link to="/shop">Go to Shop.</Link></td>
                    </tr>
                  ) : (
                    parsedWishlist.map((item, index) => (
                      <tr className="wishlist-item" key={index}>
                        <td className="wishlist-item-remove">
                          <span onClick={() => handleRemove(item.sku)}></span>
                        </td>
                        <td className="wishlist-item-image">
                          <Link to={`/product/${item.slug}`}>
                            <img width="600" height="600" src={`/${item.images[0]}`} alt={item.name} />
                          </Link>
                        </td>
                        <td className="wishlist-item-info">
                          <div className="wishlist-item-name">
                            <Link to={`/product/${item.slug}`}>{item.name}</Link>
                          </div>
                          <div className="wishlist-item-price">
                            {item.price_discounted ? (
                              <>
                                <del aria-hidden="true"><span>${item.price.toFixed(2)}</span></del>
                                <ins><span> ${item.price_discounted.toFixed(2)}</span></ins>
                              </>
                            ) : (
                              <span>${item.price.toFixed(2)}</span>
                            )}
                          </div>
                        </td>
                        <td className="wishlist-item-actions">
                          <div className="wishlist-item-stock">In stock</div>
                          <div className="wishlist-item-add">
                            <div className="btn-add-to-cart" data-title="Add to cart">
                              {item.price_discounted ? (
                                <a href="#" rel="nofollow" className="product-btn button" onClick={(e) => {e.preventDefault();handleAddToCart(item.sku, item.price_discounted)}}>Add to cart</a>
                              ) : (
                                <a href="#" rel="nofollow" className="product-btn button" onClick={(e) => {e.preventDefault();handleAddToCart(item.sku, item.price)}}>Add to cart</a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Wishlist;
