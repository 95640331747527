import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import PageLayout from 'layouts/PageLayout';
import { login } from 'features/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

function Login() {
  useDocumentTitle('Login');

  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.auth.authenticated);
  const loginErrorMessage = useSelector(state => state.auth.loginErrorMessage);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  if (authenticated) {
    return <Navigate to="/" />;
  }

  return (
    <PageLayout headerTitle="Login">
      <div className="section-padding">
        <div className="section-container p-l-r">
          <div className="page-login-register">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="box-form-login">
                  <h2>Login</h2>
                  <p className="status" style={{textAlign: 'center', color: 'red'}}>{loginErrorMessage}</p>
                  <div className="box-content">
                    <div className="form-login">
                      <form onSubmit={handleLogin} className="login">
                        <div className="username">
                          <label htmlFor="email">Email address <span className="required">*</span></label>
                          <input type="email" className="input-text" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="password">
                          <label htmlFor="password">Password <span className="required">*</span></label>
                          <input className="input-text" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className="rememberme-lost">
                          <div className="lost-password">
                            <Link to="/auth/reset">Lost your password?</Link>
                          </div>
                        </div>
                        <div className="button-login">
                          <input type="submit" className="button" name="login" value="Login" />
                        </div>
                        <div className="button-login">
                          <Link to="/auth/register">Create an account</Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Login;
