import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('address', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('address');
  return data ? JSON.parse(data) : defaultValue;
};

const initialAddress = {
  address: {},
  allAddress: {
    'johndoe@example.com': {
      billing: { line1: 'Jl Sekarat John', line2: 'Komplek Billing No 1', line3: 'Medan, 12345', line4: '+62 234 5678 9012' },
      shipping: { line1: 'Jl Sekarat John', line2: 'Komplek Shipping No 1', line3: 'Medan, 12345', line4: '+62 234 5678 9012' },
    },
    'janedoe@example.com': {
      billing: { line1: 'Jl Sekarat Jane', line2: 'Komplek Billing No 1', line3: 'Medan, 12345', line4: '+62 234 5678 9014' },
      shipping: { line1: 'Jl Sekarat Jane', line2: 'Komplek Shipping No 1', line3: 'Medan, 12345', line4: '+62 234 5678 9014' },
    }
  }
};

const initialState = loadFromLocalStorage(initialAddress);

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setAddressByEmail: (state, action) => {
      const email = action.payload;
      if (email && state.allAddress[email]) {
        state.address = state.allAddress[email];
      }
      updateLocalStorage(state);
    },
    updateAddress: (state, action) => {
      const { email, type, data } = action.payload;
      if (!state.allAddress[email]) {
        state.allAddress[email] = {};
      }
      state.allAddress[email][type] = data;
      state.address = state.allAddress[email];
      updateLocalStorage(state);
    },
    clearAddress: (state) => {
      state.address = {};
      updateLocalStorage(state);
    }
  }
});

export const { setAddress, setAddressByEmail, updateAddress, clearAddress } = addressSlice.actions;
export default addressSlice.reducer;
