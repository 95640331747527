import React, { useEffect, useRef, useState } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import PageLayout from 'layouts/PageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from 'features/authSlice';
import { useDocumentTitle } from 'hooks/useDocumentTitle';

function ResetPassword() {
  useDocumentTitle('Password Reset');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authenticated = useSelector(state => state.auth.authenticated);
  const updateErrorMessageRef = useRef('');
  const updateErrorMessage = useSelector(state => state.auth.updateErrorMessage);
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  useEffect(() => {
    // @credit https://stackoverflow.com/a/77228056/8428965
    updateErrorMessageRef.current = updateErrorMessage;
  }, [updateErrorMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== newPasswordConfirm) {
      alert('Passwords do not match');
      return;
    }
    dispatch(resetPassword({ token, newPassword }));
    setTimeout(() => {
      if (!updateErrorMessageRef.current) {
        alert('Password has been reset successfully!');
        navigate('/auth/login');
      }
    }, 100);
  };

  if (authenticated) {
    return <Navigate to="/" />;
  }

  return (
    <PageLayout headerTitle="Reset Password">
      <div className="section-padding">
        <div className="section-container p-l-r">
          <div className="page-login-register">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="box-form-login">
                  <h2>Reset Password</h2>
                  <div className="box-content">
                    <div className="form-login">
                      <form onSubmit={handleSubmit} className="login">
                        <div className="password">
                          <label htmlFor="newPassword">New Password <span className="required">*</span></label>
                          <input type="password" className="input-text" name="newPassword" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                        </div>
                        <div className="password-confirm">
                          <label htmlFor="newPasswordConfirm">Confirm New Password <span className="required">*</span></label>
                          <input type="password" className="input-text" name="newPasswordConfirm" id="newPasswordConfirm" value={newPasswordConfirm} onChange={(e) => setNewPasswordConfirm(e.target.value)} required />
                        </div>
                        <div className="button-login">
                          <input type="submit" className="button" name="reset" value="Reset Password" />
                        </div>
                        {updateErrorMessage && <p style={{ color: 'red', textAlign: 'center' }}>{updateErrorMessage}</p>}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default ResetPassword;
