import React from 'react';
import PageLayout from 'layouts/PageLayout';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { logout } from 'features/authSlice';
import OrderTab from './OrdersTab';
import AddressTab from './AddressTab';
import AccountDetailsTab from './AccountDetailsTab';
import UserQuestions from './UserQuestionsTab';
import NewsletterSubscribersTab from './NewsletterSubscribersTab';

function MyAccount() {
  useDocumentTitle('My Account');

  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.auth.authenticated);
  const user = useSelector(state => state.auth.user);
  if (!authenticated) {
    return <Navigate to="/auth/login" />
  }

  const handleLogout = (e) => {
    dispatch(logout());
  };

  return (
    <PageLayout headerTitle="My Account">
      <div className="section-padding">
        <div className="section-container p-l-r">
          <div className="page-my-account">
            <div className="my-account-wrap clearfix">
              <nav className="my-account-navigation">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#dashboard" role="tab">Dashboard</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#orders" role="tab">Orders</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#addresses" role="tab">Addresses</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#account-details" role="tab">Account details</a>
                  </li>
                  { !!(user.isAdmin) && <>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#questions" role="tab">View User Questions</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#subscribers" role="tab">View Newsletter Subscribers</a>
                  </li> </>}
                  <li className="nav-item">
                    <a className="nav-link" href="#" onClick={handleLogout}>Logout</a>
                  </li>
                </ul>
              </nav>
              <div className="my-account-content tab-content">
                <div className="tab-pane fade show active" id="dashboard" role="tabpanel">
                  <div className="my-account-dashboard">
                    <p>
                      Hello <strong>{user.displayName}</strong> (not <strong>{user.displayName}</strong>? <a href="#" onClick={handleLogout}>Log out</a>)
                    </p>
                    <p>
                      From your account dashboard you can view your <a href="#">recent orders</a>, manage your <a href="#">shipping and billing addresses</a>, and <a href="#">edit your password and account details</a>.
                    </p>
                  </div>
                </div>
                <OrderTab />
                <AddressTab />
                <AccountDetailsTab />
                { !!(user.isAdmin) && <>
                <UserQuestions />
                <NewsletterSubscribersTab /> </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default MyAccount;