import { useDocumentTitle } from 'hooks/useDocumentTitle';
import PageLayout from 'layouts/PageLayout';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { register } from 'features/authSlice';

function Register() {
  useDocumentTitle('Register');

  const dispatch = useDispatch();
  const authenticated = useSelector(state => state.auth.authenticated);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const registerErrorMessage = useSelector(state => state.auth.registerErrorMessage);

  const handleRegister = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Password and confirmation do not match.');
      return;
    }
    dispatch(register({ email, password, firstName, lastName }));
  };

  if (authenticated) {
    return <Navigate to="/" />;
  }

  return (
    <PageLayout headerTitle="Register">
      <div className="section-padding">
        <div className="section-container p-l-r">
          <div className="page-login-register">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="box-form-login">
                  <h2>Register</h2>
                  <p className="status" style={{textAlign: 'center', color: 'red'}}>{registerErrorMessage}</p>
                  <div className="box-content">
                    <div className="form-register">
                      <form onSubmit={handleRegister} className="register">
                      <div className="email">
                          <label htmlFor="email">Email address <span className="required">*</span></label>
                          <input type="email" className="input-text" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                        <div className="email">
                          <label htmlFor="firstName">First Name <span className="required">*</span></label>
                          <input type="text" className="input-text" name="firstName" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                        </div>
                        <div className="email">
                          <label htmlFor="lastName">Last Name <span className="required">*</span></label>
                          <input type="text" className="input-text" name="lastName" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                        </div>
                        <div className="password">
                          <label htmlFor="password">Password <span className="required">*</span></label>
                          <input className="input-text" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </div>
                        <div className="confirm-password">
                          <label htmlFor="confirm-password">Confirm Password <span className="required">*</span></label>
                          <input className="input-text" type="password" name="confirm-password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                        </div>
                        <div className="button-register">
                          <input type="submit" className="button" name="register" value="Register" />
                        </div>
                        <div className="button-register">
                          <Link to="/auth/login">Already have an account</Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Register;
