import { createSlice } from '@reduxjs/toolkit';

const updateLocalStorage = (data) => {
  localStorage.setItem('newsletter', JSON.stringify(data));
};

const loadFromLocalStorage = (defaultValue) => {
  const data = localStorage.getItem('newsletter');
  return data ? JSON.parse(data) : defaultValue;
};

const initialNewsletter = {
  subscribers: []
};

const initialState = loadFromLocalStorage(initialNewsletter);

const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState,
  reducers: {
    addSubscriber: {
      reducer(state, action) {
        const exists = state.subscribers.find(subscriber => subscriber.email === action.payload.email);
        if (!exists) {
          state.subscribers.push(action.payload);
        }
      },
      prepare(email) {
        return {
          payload: {
            email,
            subscribedAt: new Date().toISOString()
          }
        };
      }
    },
    removeSubscriber(state, action) {
      state.subscribers = state.subscribers.filter(subscriber => subscriber.email !== action.payload);
    }
  }
});

export const { addSubscriber, removeSubscriber } = newsletterSlice.actions;
export default newsletterSlice.reducer;
