import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from "pages/Home";
import Shop from "pages/Shop/Shop";
import Wishlist from "pages/Wishlist";
import NotFound from "pages/NotFound";
import MyAccount from "pages/MyAccount/MyAccount";
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import ResetRequest from "pages/Auth/ResetRequest";
import ResetPassword from "pages/Auth/ResetPassword";
import Contact from "pages/Contact";
import Cart from "pages/Cart";
import OrderDetails from "pages/MyAccount/OrderDetails";
import ProductDetails from "pages/Shop/ProductDetails";

function App() {
  // For initializing the template code
  const location = useLocation();
  useEffect(() => {
    // TODO: This effect is called multiple times because of "rerender" even though the location doesnt change.
    // Causing a bit of stutter when loading.
    window.jQuery(".page-preloader").show();
    window.scroll(0, 0);
    window.initializeSlickSliders();
    window.initializeTemplateJs();
    setTimeout(function () {
      window.jQuery(".page-preloader").fadeOut();
    }, 500);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="auth">
        {/* The routes in this group are guest-only */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="reset" element={<ResetRequest />} />
        <Route path="reset/:token" element={<ResetPassword />} />
      </Route>

      <Route path="cart" element={<Cart />} />
      <Route path="contact" element={<Contact />} />

      <Route path="shop" element={<Shop />} />
      <Route path="product/:productSlug" element={<ProductDetails />} />

      {/* The following routes are protected */}
      <Route path="wishlist" element={<Wishlist />} />
      <Route path="my-account" element={<MyAccount />} />
      <Route path="orders/:orderId/details" element={<OrderDetails />}/>

      {/* Error route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
