import React from 'react';
import { useDispatch } from 'react-redux';
import { quickviewProduct } from 'features/quickviewSlice';
import AddToWishlistButton from './Components/AddToWishlistButton';
import AddToCartButton from './Components/AddToCartButton';
import CompareItem from './Components/CompareItem';

function ProductCard({ product }) {
  const dispatch = useDispatch();

  const handleQuickView = () => {
    dispatch(quickviewProduct({ product }));
    window.jQuery(".quickview-popup").addClass("active");
  };

  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
      <div className="products-entry clearfix product-wapper">
        <div className="products-thumb">
          <div className="product-label">
            {product.price_discounted && <div className="onsale">-{Math.round((product.price - product.price_discounted) / product.price * 100)}%</div>}
            {product.rating >= 4 && <div className="hot">Hot</div>}
          </div>
          <div className="product-thumb-hover">
            <a href={`/product/${product.slug}`}>
              <img width="600" height="600" src={product.images[0]} className="post-image" alt={product.name} />
              {product.images[1] && <img width="600" height="600" src={product.images[1]} className="hover-image back" alt={product.name} />}
            </a>
          </div>
          <div className="product-button">
            <AddToCartButton product={product} />
            <AddToWishlistButton sku={product.sku} />
            <CompareItem product={product} />
            <span className="product-quickview" data-title="Quick View">
							<a href="#" className="quickview quickview-button" onClick={(e) => {e.preventDefault();handleQuickView(product)}}>Quick View <i className="icon-search"></i></a>
						</span>
          </div>
        </div>
        <div className="products-content">
          <div className="contents text-center">
            <h3 className="product-title"><a href={`/product/${product.slug}`}>{product.name}</a></h3>
            <span className="price">
              {product.price_discounted ? (
                <>
                  <del aria-hidden="true"><span>${product.price.toFixed(2)}</span></del>
                  <ins><span>${product.price_discounted.toFixed(2)}</span></ins>
                </>
              ) : (
                <span>${product.price.toFixed(2)}</span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
