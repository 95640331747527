/**
 * A custom helper class to parse all our data
 */
const BerryProducts = {
    parseProduct(product) {
        const totalRatings = product.reviews.reduce((sum, review) => sum + review.rating, 0);
        const averageRating = totalRatings / product.reviews.length;
        const discounted = (product.price_discounted) ? Math.floor(((product.price - product.price_discounted) / product.price) * 100) : null;

        return {
            ...product, 
            slug: product.name.toLowerCase().replaceAll(' ', '-'),
            rating: averageRating,
            discount: discounted || 0
        };
    },
    parseProducts(products) {
        return products.map((e) => this.parseProduct(e));
    },
    indexProducts(products) {
        let index_sku = {},
            index_slug = {},
            index_category = {};

        products.forEach(product => {
            product = this.parseProduct(product);
            index_sku[product.sku.toLowerCase()] = product;
            index_slug[product.slug] = product;
            index_category[product.category.toLowerCase()] = index_category[product.category.toLowerCase()] || [];
            index_category[product.category.toLowerCase()].push(product);
        });

        return { index_sku, index_slug, index_category };
    },
    getProductBySKU(sku, products) {
        const index = this.indexProducts(products).index_sku;
        return index[sku.toLowerCase()] ?? null;
    },
    getProductsBySKUs(skus, products, extra_assoc = null) {
        const index = this.indexProducts(products).index_sku;
        skus = skus.filter(Boolean);
        
        let count = 0;
        const result = skus.map((sku) => {
            const product = index[sku.toLowerCase()];
            if (!product) {
                count++; // Because we are associating, so have to skip as well
                return null;
            }
            if (extra_assoc && extra_assoc[count]) {
                const mergedProduct = { ...product, ...extra_assoc[count] };
                count++;
                return mergedProduct;
            }
            count++;
            return product;
        });
        return result.filter(Boolean);
    },    
    getProductBySlug(slug, products) {
        const index = this.indexProducts(products).index_slug;
        return index[slug.toLowerCase()] ?? null; 
    },
    getFilteredProducts(filters = {}, products) {
        const { query, priceMin, priceMax, category, size, brand, color } = filters;

        return products.filter(product => {
            if (query && !(
                product.name.toLowerCase().includes(query.toLowerCase()) || 
                product.description.toLowerCase().includes(query.toLowerCase())
            )) {
                return false;
            }
            if (priceMin !== undefined && product.price < priceMin) {
                return false;
            }
            if (priceMax !== undefined && product.price > priceMax) {
                return false;
            }
            if (category && product.category.toLowerCase() !== category.toLowerCase()) {
                return false;
            }
            if (size && product.size.toLowerCase() !== size.toLowerCase()) {
                return false;
            }
            if (brand && product.brand.toLowerCase() !== brand.toLowerCase()) {
                return false;
            }
            if (color && product.color.toLowerCase() !== color.toLowerCase()) {
                return false;
            }

            return true;
        });
    },
    getFiltersMetadata(products) {
        let minPrice = null,
            maxPrice = null,
            sizes = [],
            brands = [],
            categories = {};
    
        products.forEach(product => {
            let price = product.price_discounted || product.price;
            if (price < minPrice || minPrice == null) {
                minPrice = price;
            }
            if (price > maxPrice) {
                maxPrice = price;
            }
            product.variations.size.forEach(size => {
                if (!sizes.includes(size)) {
                    sizes.push(size);
                }
            });
            if (!brands.includes(product.brand)) {
                brands.push(product.brand);
            }
            if (!categories[product.category]) {
                categories[product.category] = 0;
            }
            categories[product.category]++;
        });
    
        return { 
            minPrice, 
            maxPrice, 
            sizes, 
            brands, 
            categories: Object.keys(categories).map(key => ({ category: key, count: categories[key] }))
        }
    }
};

export default BerryProducts;
