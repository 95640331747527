import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function WishlistIcon() {
  const wishlistCount = useSelector((state) => state.wishlist.wishlist.length);

  return (
    <div className="wishlist-box">
      <Link to="/wishlist"><i className="icon-heart"></i></Link>
      <span className="count-wishlist">{wishlistCount}</span>
    </div>
  );
}

export default WishlistIcon;
