import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addSubscriber } from 'features/newsletterSlice';
import { notify } from 'app/helper';

function Footer() {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (email) {
      dispatch(addSubscriber(email));
      notify("You've successfully subscribed to the newsletter!");
      setEmail('');
    } else {
      notify("Please enter a valid email address.");
    }
  };

  const handleClearLocalData = (e) => {
    e.preventDefault();
    const confirmation = window.confirm("Are you sure you want to clear all local data?");
    if (confirmation) {
      window.localStorage.clear();
      window.location.reload();
    }
  };

  return (
    <footer id="site-footer" className="site-footer">
      <div className="footer">
        <div className="section-padding">
          <div className="section-container">
            <div className="block-widget-wrap">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="block block-image">
                    <img width="100" src="/media/logo.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="block block-menu">
                    <h2 className="block-title">Contact Us</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <Link to="/contact">616.774.0561</Link>
                        </li>
                        <li>
                          <Link to="/contact">866.453.4748</Link>
                        </li>
                        <li>
                          <Link to="/contact">HR Fax: 810.222.5439</Link>
                        </li>
                        <li>
                          <Link to="/contact">sales@berryfurniture.com</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="block block-menu">
                    <h2 className="block-title">Categorie</h2>
                    <div className="block-content">
                      <ul>
                        <li>
                          <a href="#" onClick={handleClearLocalData} style={{color: 'red'}}>Clear All Local Data</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="block block-newsletter">
                    <h2 className="block-title">Newsletter</h2>
                    <div className="block-content">
                      <div className="newsletter-text">Enter your email below to be the first to know about new collections and product launches.</div>
                      <form action="#" method="post" className="newsletter-form" onSubmit={handleSubscribe}>
                        <input 
                          type="email" 
                          name="your-email" 
                          size="40" 
                          placeholder="Email address" 
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <span className="btn-submit">
                          <input type="submit" />
                        </span>
                      </form>
                    </div>
                  </div>

                  <div className="block block-image">
                    <img width="400" height="79" src="/media/payments.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="section-padding">
          <div className="section-container">
            <div className="block-widget-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-left">
                    <p className="copyright">Project "Kelompok" UAS - Berry Furniture Store</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
