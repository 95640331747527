import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shouldShow: false,
    product: null
};

const quickviewSlice = createSlice({
  name: 'quickview',
  initialState,
  reducers: {
    quickviewProduct: (state, action) => {
      const { product } = action.payload;
      state.product = product;
      state.shouldShow = true;
    },
    closeQuickview: (state) => {
      state.shouldShow = false;
    }
  }
});

export const { quickviewProduct, closeQuickview } = quickviewSlice.actions;
export default quickviewSlice.reducer;
