import React from 'react'
import WishlistModal from './Modal/WishlistModal'
import CompareModal from './Modal/CompareModal'
import QuickViewModal from './Modal/QuickViewModal'

function CommonModalComponents() {
  return (
    <>
      <WishlistModal />
      <CompareModal />
      <QuickViewModal />
    </>
  )
}

export default CommonModalComponents
