import React from 'react'
import { Link } from 'react-router-dom'

function SectionVideo() {
  return (
    <section className="section section-padding background-1 section-mb-l">
      <div className="section-container">
        {/* Section Video */}
        <div className="block block-video">
          <div className="section-row">
            <div className="section-column left">
              <div className="section-column-wrap">
                <div className="block-widget-wrap">
                  <div className="block-widget-video">
                    <div className="video-thumb">
                      <img width="565" height="635" className="img-responsive" src="media/banner/video-1.jpg"
                        alt="Image Video" />
                    </div>
                    <div className="video-wrap">
                      <div className="video" data-src="https://www.youtube.com/embed/VQOJaYUPZR8" data-toggle="modal"
                        data-target="#video-popup">
                        <i className="fa fa-play" aria-hidden="true"></i>
                      </div>
                      <div className="content-video modal fade" id="video-popup" tabIndex="-1" role="dialog"
                        aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div className="modal-body">
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                              <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" src="#" id="video" allowscriptaccess="always"
                                  allow="autoplay"></iframe>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-column right">
              <div className="section-column-wrap">
                <div className="block-widget-wrap">
                  <div className="block-widget-video">
                    <div className="video-text">
                      <h2 className="title">From bottle <br/>to chair</h2>
                      <div className="description">In our design and production processes, we are always looking at
                        where environmental thinking and economic improvement intersect – resulting in minimal
                        waste in every aspect. The way we utilise PET Technology, emphasizes this vision.</div>
                      <Link to="/shop" className="button button-white">OUR STORIES</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionVideo;