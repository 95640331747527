import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  category: '',
  minPrice: null,
  maxPrice: null,
  size: '',
  brand: '',
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setCategoryFilter: (state, action) => {
      state.category = action.payload;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setSizeFilter: (state, action) => {
      state.size = action.payload;
    },
    setBrandFilter: (state, action) => {
      state.brand = action.payload;
    },
  },
});

export const { setCategoryFilter, setMinPrice, setMaxPrice, setSizeFilter, setBrandFilter } = filtersSlice.actions;
export default filtersSlice.reducer;
