import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from 'features/cartSlice';
import { notify } from 'app/helper';
import { Link } from 'react-router-dom';

function AddToCartButton({ product }) {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart.items);

  const isProductInCart = cart.some(item => item.sku === product.sku);

  const handleAddToCart = (e) => {
    e.preventDefault();
    if (isProductInCart) {
      notify("Product is already in the cart!");
    } else {
      dispatch(addToCart({ sku: product.sku, qty: 1, price: product.price }));
      notify("Product added to cart!");
    }
  };

  return (
    <div className="btn-add-to-cart" data-title="Add to cart">
      <a href='#' onClick={handleAddToCart} className={`product-btn button ${isProductInCart ? 'added' : ''}`}>Add to cart</a>
      {isProductInCart && <Link to="/cart" className="added-to-cart product-btn" title="View cart" tabIndex="0">View cart</Link>}
    </div>
  );
}

export default AddToCartButton;
