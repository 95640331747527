import { notify } from 'app/helper';
import { removeSubscriber } from 'features/newsletterSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function NewsletterSubscribersTab() {
  const dispatch = useDispatch();
  const subscribers = useSelector(state => state.newsletter.subscribers);

  const handleRemove = (email) => {
    dispatch(removeSubscriber(email));
    notify(`Subscriber with email ${email} has been removed.`);
  }

  return (
    <div className="tab-pane fade" id="subscribers" role="tabpanel">
      <div className="my-account-orders">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Subscribed Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {subscribers.map((subscriber) => (
                <tr key={subscriber.email}>
                  <td>{subscriber.email}</td>
                  <td>{new Date(subscriber.subscribedAt).toLocaleDateString()}</td>
                  <td onClick={() => handleRemove(subscriber.email)} style={{ cursor: 'pointer', color: 'black' }}>Remove</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default NewsletterSubscribersTab;
