import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeFromCart } from 'features/cartSlice';
import BerryProducts from 'app/BerryProducts';

function CartDropdown() {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const products = useSelector(state => state.products.products);
  const [parsedCart, setParsedCart] = useState([]);

  useEffect(() => {
    if (cart.items.length > 0 && products.length > 0) {
      const skus = cart.items.map(e => e.sku);
      const parsed = BerryProducts.getProductsBySKUs(skus, products, cart.items);
      setParsedCart(parsed);
    } else {
      setParsedCart([]);
    }
  }, [cart.items, products]);

  const handleRemoveItem = (sku) => {
    dispatch(removeFromCart(sku));
  };

  return (
    <div className="ruper-topcart dropdown">
      <div className="dropdown mini-cart top-cart">
        <div className="remove-cart-shadow"></div>
        <a className="dropdown-toggle cart-icon" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div className="icons-cart">
            <i className="icon-large-paper-bag"></i>
            <span className="cart-count">{cart.items.length}</span>
          </div>
        </a>
        <div className="dropdown-menu cart-popup">
          {parsedCart.length === 0 ? (
            <div className="cart-empty-wrap">
              <ul className="cart-list">
                <li className="empty">
                  <span>No products in the cart.</span>
                  <Link className="go-shop" to="/shop">GO TO SHOP<i aria-hidden="true" className="arrow_right"></i></Link>
                </li>
              </ul>
            </div>
          ) : (
            <div className="cart-list-wrap">
              <ul className="cart-list">
                {parsedCart.map(item => (
                  <li key={item.sku} className="mini-cart-item">
                    <a href="#" className="remove" title="Remove this item" onClick={(e) => {e.preventDefault();handleRemoveItem(item.sku)}}><i className="icon_close"></i></a>
                    <Link to={`/product/${item.sku.toLowerCase()}`} className="product-image">
                      <img width="600" height="600" src={`/${item.images[0]}`} alt={item.name} />
                    </Link>
                    <Link to={`/product/${item.sku.toLowerCase()}`} className="product-name">{item.name}</Link>
                    <div className="quantity">Qty: {item.qty}</div>
                    <div className="price">${item.price.toFixed(2)}</div>
                  </li>
                ))}
              </ul>
              <div className="total-cart">
                <div className="title-total">Total: </div>
                <div className="total-price"><span>${cart.totalPrice.toFixed(2)}</span></div>
              </div>
              <div className="free-ship">
                {cart.totalPrice >= cart.minimumFreeShipping ? (
                  <div className="title-ship">Enjoy <strong>FREE Shipping</strong> for this order</div>
                ) : (
                  <div className="title-ship">Buy <strong>${(cart.minimumFreeShipping - cart.totalPrice).toFixed(2)}</strong> more to enjoy <strong>FREE Shipping</strong></div>
                )}
                <div className="total-percent">
                  <div className="percent" style={{ width: `${Math.min(Math.floor((cart.totalPrice / cart.minimumFreeShipping) * 100), 100)}%` }}></div>
                </div>
              </div>
              <div className="buttons">
                <Link to="/cart" className="button btn view-cart btn-primary">View cart</Link>
                <Link to="/checkout" className="button btn checkout btn-default">Check out</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartDropdown;
