import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import BerryProducts from 'app/BerryProducts';
import { updateCartItemQty, removeFromCart } from 'features/cartSlice';
import PageLayout from 'layouts/PageLayout';

function Cart() {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const products = useSelector(state => state.products.products);
  const minimumOrderForFreeShipping = useSelector(state => state.cart.minimumFreeShipping);
  const [parsedCart, setParsedCart] = useState([]);

  useEffect(() => {
    if (cart.items.length > 0 && products.length > 0) {
      const skus = cart.items.map(e => e.sku);
      const parsed = BerryProducts.getProductsBySKUs(skus, products, cart.items);
      setParsedCart(parsed);
    } else {
      setParsedCart([]);
    }
  }, [cart.items, products]);

  const handleQuantityChange = (sku, newQuantity) => {
    if (newQuantity < 0) {
      removeFromCart({ sku });
      return;
    }
    dispatch(updateCartItemQty({ sku, qty: newQuantity }));
  };

  const handleRemoveItem = (sku) => {
    dispatch(removeFromCart({ sku }));
  };

  const getCartSubtotal = () => {
    return parsedCart.reduce((total, item) => total + item.price * item.qty, 0);
  };

  const getShippingMethod = () => {
    return getCartSubtotal() >= minimumOrderForFreeShipping ? 'Free shipping' : 'Flat rate';
  };

  return (
    <PageLayout headerTitle="Cart">
      <div className="section-padding">
        <div className="section-container p-l-r">
        <div className="shop-cart">
            {parsedCart.length > 0 ? (
              <div className="row">
                <div className="col-xl-8 col-lg-12 col-md-12 col-12">
                  <form className="cart-form" action="#" onSubmit={(e) => e.preventDefault()}>
                    <div className="table-responsive">
                      <table className="cart-items table" cellSpacing="0">
                        <thead>
                          <tr>
                            <th className="product-thumbnail">Product</th>
                            <th className="product-price">Price</th>
                            <th className="product-quantity">Quantity</th>
                            <th className="product-subtotal">Subtotal</th>
                            <th className="product-remove">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {parsedCart.map(item => (
                            <tr className="cart-item" key={item.sku}>
                              <td className="product-thumbnail">
                                <Link to={`/product/${item.sku.toLowerCase()}`}>
                                  <img width="600" height="600" src={`/${item.images[0]}`} className="product-image" alt={item.name} />
                                </Link>
                                <div className="product-name">
                                  <Link to={`/product/${item.sku.toLowerCase()}`}>{item.name}</Link>
                                </div>
                              </td>
                              <td className="product-price"> <span className="price">${item.price.toFixed(2)}</span></td>
                              <td className="product-quantity">
                                <div className="quantity">
                                  <button type="button" className="minus" onClick={() => handleQuantityChange(item.sku, Math.max(0, item.qty - 1))}> - </button>
                                  <input type="number" className="qty" step="1" min="1" name="quantity" value={item.qty} title="Qty" size="4" placeholder="" inputMode="numeric" autoComplete="off" onChange={(e) => handleQuantityChange(item.sku, parseInt(e.target.value))} />
                                  <button type="button" className="plus" onClick={() => handleQuantityChange(item.sku, item.qty + 1)}> + </button>
                                </div>
                              </td>
                              <td className="product-subtotal"> <span>${(item.price * item.qty).toFixed(2)}</span></td>
                              <td className="product-remove"> <a href="#" className="remove" onClick={(e) => {e.preventDefault();handleRemoveItem(item.sku)}}> × </a></td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan="6" className="actions">
                              <div className="bottom-cart">
                                <h2><Link to="/shop">Continue Shopping</Link></h2>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </form>
                </div>
                <div className="col-xl-4 col-lg-12 col-md-12 col-12">
                  <div className="cart-totals">
                    <h2>Cart totals</h2>
                    <div>
                      <div className="cart-subtotal">
                        <div className="title">Subtotal</div>
                        <div><span>${getCartSubtotal().toFixed(2)}</span></div>
                      </div>
                      <div className="shipping-totals">
                        <div className="title">Shipping</div>
                        <div>
                          <ul className="shipping-methods custom-radio">
                            <li>
                              <input type="radio" name="shipping_method" value="free_shipping" className="shipping_method" checked={getShippingMethod() === 'Free shipping'} readOnly />
                              <label>Free shipping</label>
                            </li>
                            <li>
                              <input type="radio" name="shipping_method" value="flat_rate" className="shipping_method" checked={getShippingMethod() === 'Flat rate'} readOnly />
                              <label>Flat rate</label>
                            </li>
                          </ul>
                          <p className="shipping-desc">
                            Shipping options will be updated during checkout.
                          </p>
                        </div>
                      </div>
                      <div className="order-total">
                        <div className="title">Total</div>
                        <div><span>${getCartSubtotal().toFixed(2)}</span></div>
                      </div>
                    </div>
                    <div className="proceed-to-checkout">
                      <Link to="/checkout" className="checkout-button button">
                        Proceed to checkout
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="shop-cart-empty">
                <div className="notices-wrapper">
                  <p className="cart-empty">Your cart is currently empty.</p>
                </div>
                <div className="return-to-shop">
                  <Link className="button" to="/shop">
                    Return to shop
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Cart;
